import { Flex, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const Footer = () => {
  const textColor = useColorModeValue('gray.400', 'white');
  const linkColor = useColorModeValue({ base: 'gray.400', lg: 'white' }, 'white');

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '0px' }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', lg: '0px' }}
      >
        {' '}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          Dapat Komisi. All Rights Reserved.
          <Link
            mx="3px"
            color={textColor}
            href="https://www.DapatKomisi.com?ref=dapatkomisi-chakra-free"
            target="_blank"
            fontWeight="700"
          >
            DapatKomisi!
          </Link>
        </Text>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={linkColor} href="mailto:hello@DapatKomisi.com?ref=dapatkomisi-chakra-free">
            Support
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link
            fontWeight="500"
            color={linkColor}
            href="https://www.DapatKomisi.com/licenses?ref=dapatkomisi-chakra-free"
          >
            License
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link
            fontWeight="500"
            color={linkColor}
            href="https://DapatKomisi.com/terms-of-service?ref=dapatkomisi-chakra-free"
          >
            Terms of Use
          </Link>
        </ListItem>
        <ListItem>
          <Link fontWeight="500" color={linkColor} href="https://www.blog.DapatKomisi.com/?ref=dapatkomisi-chakra-free">
            Blog
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
};

export default Footer;
