/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  useColorModeValue,
  Stack,
  Text,
  SimpleGrid,
  Image,
} from '@chakra-ui/react';
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import RatingStars from 'components/rating';
import PaginationHandler from 'components/table/components/PaginationHandler';
import formatRupiah from 'lib/formatRupiah';
import useStore from 'store/useStore';

const ProductCard = ({ product, affliate, affliate_id }) => {
  const { generateAffliateLink } = useStore();
  const [isAffiliate, setIsAffiliate] = useState(affliate);
  const [affiliateData, setAffiliateData] = useState({});

  if (affliate) {
    return (
      <Box
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        padding={2}
        display={'flex'}
        flexDirection={'column'}
      >
        <Image
          height="200px" // Set the default height for the image
          objectFit="cover" // This will cover the height if the image is taller than 200px
          width="200px" // Make the image responsive to the width of the card
          src={product.variants[0]?.images[0]}
          alignItems={'center'}
          alignSelf={'center'}
          alt={`Picture of ${product?.title}`}
        />
        <Flex mt={'4'} bg={'green.200'} borderRadius={'10'} p={'2'}>
          <Text color={'green.900'} fontWeight={500} fontSize={'md'}>
            {product?.dropship_commission
              ? product?.dropship_commission.commission.type === 'number'
                ? formatRupiah(product?.dropship_commission.commission.value)
                : `${product?.dropship_commission.commission.value}%`
              : '0'}{' '}
            komisi
          </Text>
        </Flex>
        <Box p="2">
          {/* <Box display="flex" alignItems="baseline">
            {product?.isNew && (
              <Badge borderRadius="full" px="2" colorScheme="teal">
                New
              </Badge>
            )}
          </Box> */}
          <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
            {product?.title}
          </Box>
          <Box>
            {formatRupiah(product?.variants[0]?.price)}
            <Box as="span" color="gray.600" fontSize="sm">
              / unit
            </Box>
          </Box>
          <Box>{product?.variants[0]?.stocks} stocks</Box>

          <Stack align="center" justify="space-between" direction="row" mt={6}>
            <Text fontWeight={800} fontSize={'xl'}>
              {product?.rating ? String(product?.rating).slice(0, 3) : 0} / 5
            </Text>
            <RatingStars rating={product?.rating ? String(product?.rating).slice(0, 3) : 0} />
          </Stack>
        </Box>
        <Button
          onClick={async () => {
            isAffiliate
              ? toast.promise(
                  navigator.clipboard.writeText(
                    `https://dapatkomisi.com/affiliate?id=${affliate_id || affiliateData._id}`,
                  ),
                  {
                    loading: 'Copying Text',
                    success: 'Success Copying Affiliate Product',
                    error: 'Error when fetching',
                  },
                  {
                    position: 'bottom-center',
                  },
                )
              : toast.promise(
                  generateAffliateLink({ product: product?._id }).then((res) => {
                    setAffiliateData({ ...res.affiliate });
                    setIsAffiliate(true);
                  }),
                  {
                    loading: 'Loading',
                    success: 'Generate Affiliate Product',
                    error: 'Error when fetching',
                  },
                  {
                    position: 'bottom-center',
                  },
                );
          }}
          mt={2}
          colorScheme="red"
        >
          {isAffiliate ? 'Salin Tautan' : 'Dapatkan Penghasilan'}
        </Button>
      </Box>
    );
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'2xl'}
      padding={2}
      display={'flex'}
      flexDirection={'column'}
    >
      <Image
        height="200px" // Set the default height for the image
        objectFit="cover" // This will cover the height if the image is taller than 200px
        width="200px" // Make the image responsive to the width of the card
        src={product.variants[0]?.images[0]}
        alignItems={'center'}
        alignSelf={'center'}
        alt={`Picture of ${product?.title}`}
      />
      <Flex mt={'4'} bg={'green.200'} borderRadius={'10'} p={'2'}>
        <Text color={'green.900'} fontWeight={500} fontSize={'md'}>
          {product?.dropship_commission
            ? product?.dropship_commission.commission.type === 'number'
              ? formatRupiah(product?.dropship_commission.commission.value)
              : `${product?.dropship_commission.commission.value}%`
            : '0'}{' '}
          komisi
        </Text>
      </Flex>
      <Box p="2">
        {/* <Box display="flex" alignItems="baseline">
          {product?.isNew && (
            <Badge borderRadius="full" px="2" colorScheme="teal">
              New
            </Badge>
          )}
        </Box> */}
        <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
          {product?.title}
        </Box>
        <Box>
          {formatRupiah(product?.variants[0]?.price)}
          <Box as="span" color="gray.600" fontSize="sm">
            / unit
          </Box>
        </Box>
        <Box>{product?.variants[0]?.stocks} stocks</Box>

        <Stack align="center" justify="space-between" direction="row" mt={6}>
          <Text fontWeight={800} fontSize={'xl'}>
            {product?.rating ? String(product?.rating).slice(0, 3) : 0} / 5
          </Text>
          <RatingStars rating={product?.rating ? String(product?.rating).slice(0, 3) : 0} />
        </Stack>
      </Box>
      <Button
        onClick={async () => {
          isAffiliate
            ? toast.promise(
                navigator.clipboard.writeText(
                  `https://dapatkomisi.com/affiliate?id=${affliate_id || affiliateData._id}`,
                ),
                {
                  loading: 'Copying Text',
                  success: 'Success Copying Affiliate Product',
                  error: 'Error when fetching',
                },
                {
                  position: 'bottom-center',
                },
              )
            : toast.promise(
                generateAffliateLink({ product: product?._id }).then((res) => {
                  setAffiliateData({ ...res.affiliate });
                  setIsAffiliate(true);
                }),
                {
                  loading: 'Loading',
                  success: 'Generate Affiliate Product',
                  error: 'Error when fetching',
                },
                {
                  position: 'bottom-center',
                },
              );
        }}
        mt={2}
        colorScheme="red"
      >
        {isAffiliate ? 'Salin Tautan' : 'Dapatkan Penghasilan'}
      </Button>
    </Box>
  );
};

ProductCard.propTypes = {
  affliate_id: PropTypes.string,
  affliate: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    affliate: PropTypes.bool,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        price: PropTypes.number,
        description: PropTypes.string,
        stocks: PropTypes.number,
      }),
    ),
  }),
};

const FindProduct = () => {
  const formSearchProduct = useForm({
    defaultValues: {
      search: '',
    },
  });

  const { getProductAffiliate, reseller_product } = useStore();
  const {
    formState: { errors },
  } = formSearchProduct;

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
    totalPages: reseller_product?.total_pages,
  });

  const table = useReactTable({
    // data: productListAffiliate.data ?? [{}],
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: reseller_product?.total_pages || 0,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
        totalPages: reseller_product?.total_pages || 0,
      },
    },
    onPaginationChange: setPagination,
  });

  useEffect(() => {
    toast.promise(
      getProductAffiliate({
        page: pageIndex + 1,
        limit: pageSize,
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Produk Reseller',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);
  return (
    <>
      <Form
        methods={formSearchProduct}
        onSubmit={(values) => {
          getProductAffiliate({
            page: pageIndex + 1,
            limit: pageSize,
            search: values.search,
          });
        }}
      >
        <Flex dir="row">
          <FormInput
            propsFormControl={{ mb: 4 }}
            inputProps={{
              ...formSearchProduct.register('search'),
              name: 'search',
              placeholder: 'Search',
            }}
            errors={errors}
            type="TEXT"
          />
          <Button type="submit">
            <AiOutlineSearch />
          </Button>
        </Flex>
      </Form>
      <Flex flexDir={'column'}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing="20px">
          {reseller_product?.data?.map((res, index) => (
            <ProductCard key={index} product={res} />
          ))}
        </SimpleGrid>

        <PaginationHandler table={table} />
      </Flex>
    </>
  );
};

const MyAffiliateProduct = () => {
  const { getMyProductAffiliate, myProductListAffiliate } = useStore();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
    totalPages: myProductListAffiliate?.total_pages,
  });
  const table = useReactTable({
    // data: productListAffiliate.data ?? [{}],
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: myProductListAffiliate?.total_pages || 0,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
        totalPages: myProductListAffiliate?.total_pages || 0,
      },
    },
    onPaginationChange: setPagination,
  });
  useEffect(() => {
    toast.promise(
      getMyProductAffiliate({
        page: pageIndex,
        limit: pageSize,
      }),
      {
        loading: 'Loading',
        success: 'Mendapatkan Produk Reseller Saya',
        error: 'Error when fetching',
      },
      {
        position: 'bottom-center',
      },
    );
  }, []);
  return (
    <Flex flexDir={'column'}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing="20px">
        {myProductListAffiliate?.data?.map((res, index) => (
          <ProductCard affliate_id={res._id} affliate key={index} product={res.reseller_product.product} />
        ))}
      </SimpleGrid>

      <PaginationHandler table={table} />
    </Flex>
  );
};

const index = () => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} backgroundColor={'white'}>
      <Tabs isLazy>
        <TabList>
          <Tab>Cari Produk</Tab>
          <Tab>Dropship Oleh Saya</Tab>
        </TabList>
        <TabPanels>
          <TabPanel background={'white'}>
            <Box gap="20px" mb="20px">
              <FindProduct />
            </Box>
          </TabPanel>
          <TabPanel>
            <MyAffiliateProduct />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default index;
