/* eslint-disable no-nested-ternary */
import { Flex, Text, useColorModeValue, Button } from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Card from 'components/card/Card';
import Table from 'components/table';
import formatRupiah from 'lib/formatRupiah';
import useStore from 'store/useStore';

const ColumnsTable = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const pageParameter = query?.get('page');
  const limitParameter = query?.get('limit');
  const { wallet_history: walletHistory, getWalletHistory } = useStore();

  const data = useMemo(() => walletHistory || [], [JSON.stringify(walletHistory.page)]);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const statusColor = {
    completed: 'green',
    pending: 'yellow',
    failed: 'red',
  };
  const columns = [
    {
      header: 'Id transaksi',
      accessorKey: '_id',
      cell: (data) => data.getValue(),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => (
        <Button disabled colorScheme={statusColor[data.getValue()]}>
          {data.getValue()}
        </Button>
      ),
    },
    {
      header: 'Tipe',
      accessorKey: 'type',
      cell: (data) => data.getValue(),
    },
    {
      header: 'Jumlah',
      accessorKey: 'amount',
      cell: (data) => {
        return (
          <Button disabled colorScheme={data.row.original.type === 'withdrawal' ? 'red' : 'green'}>
            {data.row.original.type === 'withdrawal' ? '-' : '+'}
            {formatRupiah(data.getValue() > 0 ? data.getValue() : data.getValue() * -1)}
          </Button>
        );
      },
    },
    {
      header: 'Deskripsi',
      accessorKey: 'description',
      cell: (data) => <Text whiteSpace={'break-spaces'}>{data.getValue() ?? '-'}</Text>,
    },
    {
      header: 'Tanggal',
      accessorKey: 'date',
      cell: (data) => new Date(data.getValue()).toLocaleString(),
    },
  ];

  useEffect(() => {
    if (pageParameter) {
      setPagination((res) => ({
        ...res,
        pageIndex: Number(pageParameter),
        pageSize: Number(limitParameter),
      }));
    }
  }, [JSON.stringify(pageParameter)]);

  useEffect(() => {
    toast.promise(
      getWalletHistory({
        page: pageIndex + 1,
        limit: pageSize,
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Dompet',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space between" mb="10px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Riwayat Dompet
        </Text>
      </Flex>
      <Table
        pagination={{
          pageIndex,
          pageSize,
          totalPages: data?.total_pages,
        }}
        setPagination={setPagination}
        data={data?.data}
        columns={columns}
      />
    </Card>
  );
};

export default ColumnsTable;

// PropTypes
ColumnsTable.propTypes = {
  columnsData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
};
