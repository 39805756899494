import {
  Box,
  Button,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Input,
  FormLabel,
  FormControl,
  Select,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { useHistory, NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import formatRupiah from 'lib/formatRupiah';
import { productSchema } from 'lib/schema';
import useStore from 'store/useStore';
import MyProduct from './components/MyProduct';
import Variants from './components/variants';

const Product = () => {
  const history = useHistory();
  const form = useForm({
    resolver: zodResolver(productSchema),
    defaultValues: {
      title: '',
      description: '',
      category: '',
      affiliate_commission: {
        type: '',
        value: 0,
      },
      community: '',
      variants: [
        {
          title: '',
          price: 0,
          unit_mass: 'kg',
          stocks: 0,
          size: '',
          weight: 0,
          description: '',
        },
      ],
    },
  });

  const {
    formState: { errors },
    watch,
  } = form;

  const [createProductHandler, categoryList, getCategoryHandler] = useStore((s) => [
    s.createProduct,
    s.categoryList,
    s.getCategory,
    s.getProduct,
  ]);

  const { myStore, my_store, getAddress, address } = useStore();

  useEffect(() => {
    myStore();
    getAddress();
  }, []);

  const mappingCategoryList = categoryList?.data?.map((result) => ({
    label: result.name,
    ...result,
  }));

  const convertWeighBasedOnUnitMass = (weight, unit_mass) => {
    switch (unit_mass) {
      case 'kg': {
        return weight * 1000;
      }
      default: {
        return weight;
      }
    }
  };

  const canCreateProduct = useMemo(
    () => address?.length > 0 && address?.filter((res) => res?.is_main_address === true)?.length > 0,
    [address],
  );

  const onSubmit = async (values) => {
    try {
      const castValues = {
        ...values,
        variants: values.variants.map((value) => {
          const weight = convertWeighBasedOnUnitMass(Number(value.weight), value?.unit_mass);
          delete value?.unit_mass;
          return {
            ...value,
            price: Number(value.price),
            stocks: Number(value.stocks),
            weight: weight,
          };
        }),
      };

      if (values.community === '-' || values.community === '' || values.community === undefined) {
        delete castValues.community;
      }

      await toast.promise(
        createProductHandler(castValues),
        {
          loading: 'Memuat...',
          success: 'Produk Berhasil Di Buat',
          error: 'Terjadi Kesalahan',
        },
        {
          position: 'bottom-center',
        },
      );
      history.go(0);
    } catch (error) {
      console.log(error);
    }
  };

  const memberCommissionType = watch('affiliate_commission.type');
  const validateCommissionValue = (value, type) => {
    if (type === 'percentage' && (value < 0 || value > 100)) {
      return 'Percentage must be between 0 and 100';
    }
    if (type === 'number' && value < 0) {
      return 'Value must be a positive number';
    }
    return true;
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} backgroundColor={'white'}>
      <Tabs isLazy>
        <TabList>
          <Tab>Buat Product</Tab>
          <Tab>Produk</Tab>
        </TabList>
        <TabPanels>
          <TabPanel background={'#f3f5fe'}>
            <Box gap="20px" mb="20px">
              {!canCreateProduct && (
                <Flex
                  mb={4}
                  width={'full'}
                  px={4}
                  py={4}
                  align={'center'}
                  backgroundColor={'white'}
                  borderRadius={'full'}
                >
                  <BsFillInfoCircleFill color="#e3776f" />
                  <Text fontWeight={'bold'} ml={3}>
                    Anda baru bisa menambahkan produk ketika sudah menyimpan alamat toko anda <a></a>
                  </Text>
                  <Flex width={2} />
                  <NavLink to="/admin/store">
                    <Text color="blue.400" fontWeight="bold">
                      Halaman Toko
                    </Text>
                  </NavLink>
                </Flex>
              )}

              <Form methods={form} onSubmit={onSubmit}>
                <Flex borderRadius={'2xl'} px={10} py={10} flexDirection={'column'} backgroundColor={'white'}>
                  <FormInput
                    propsFormControl={{ mb: 4 }}
                    label="Nama Produk"
                    inputProps={{
                      ...form.register('title'),
                      name: 'title',
                      placeholder: 'Nama Produk',
                    }}
                    errors={errors}
                    type="TEXT"
                  />
                  <FormInput
                    propsFormControl={{ mb: 4 }}
                    label="Deskripsi"
                    inputProps={{
                      ...form.register('description'),
                      name: 'description',
                      placeholder: 'Deskripsi',
                    }}
                    errors={errors}
                    type="TEXTAREA"
                  />
                  <HStack mb={4} justifyContent={'center'} alignItems={'center'}>
                    <FormInput
                      propsFormControl={{
                        mb: 4,
                        onClick: () =>
                          getCategoryHandler({
                            page: 1,
                            limit: 10,
                          }),
                      }}
                      label="Kategori"
                      inputProps={{
                        ...form.register('category'),
                        name: 'category',
                        placeholder: 'Select Category',
                        optionsData: mappingCategoryList,
                        onChange: (value) => form.setValue('category', value?._id),
                      }}
                      type="SELECT"
                      error={errors}
                    />
                  </HStack>
                  <HStack mb={4} justifyContent={'center'} alignItems={'center'}>
                    <FormControl>
                      <FormLabel htmlFor="community">Masukan Produk Ke Komunitas</FormLabel>
                      <Select id="community" {...form.register('community')}>
                        <>
                          <option value={'-'}>-</option>
                          {my_store?.[0]?.community?.map((res) => {
                            if (res.status === 'active' || res.status === 'approve')
                              return (
                                <option key={res._id} value={res._id}>
                                  {res.name}
                                </option>
                              );
                          })}
                        </>
                      </Select>
                    </FormControl>
                  </HStack>
                  <HStack mb={4} justifyContent={'center'} alignItems={'center'}>
                    <FormControl>
                      <FormLabel htmlFor="affiliate-commission-type">Tipe Komisi Reseller</FormLabel>
                      <Select id="affiliate-commission-type" {...form.register('affiliate_commission.type')}>
                        <option value="percentage">Persenan</option>
                        <option value="number">Angka</option>
                      </Select>
                    </FormControl>

                    <FormControl isInvalid={errors?.affiliate_commission}>
                      <FormLabel htmlFor="affiliate-commission-value">
                        {memberCommissionType === 'percentage' ? 'Persenan Komisi Reseller (%)' : 'Jumlah Komisi'}
                      </FormLabel>
                      {memberCommissionType === 'percentage' ? (
                        <Input
                          id="affiliate-commission-value"
                          type="number"
                          {...form.register('affiliate_commission.value', {
                            required: 'This field is required',
                            valueAsNumber: true,
                            validate: (value) => validateCommissionValue(value, memberCommissionType),
                          })}
                        />
                      ) : (
                        <NumericFormat
                          {...form.register('affiliate_commission.value', {
                            required: 'This field is required',
                            valueAsNumber: true,
                            validate: (value) => validateCommissionValue(value, memberCommissionType),
                          })}
                          // eslint-disable-next-line unicorn/numeric-separators-style
                          placeholder={formatRupiah(10000)}
                          style={{ marginTop: 3 }}
                          className="input-custom"
                          valueIsNumericString={true}
                          prefix="Rp. "
                          onValueChange={({ floatValue }) => {
                            form.setValue(`affiliate_commission.value`, floatValue);
                          }}
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                        />
                      )}
                      <FormErrorMessage>
                        {errors?.affiliate_commission && errors?.affiliate_commission?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>
                </Flex>

                <Variants form={form} />
                <Button disabled={!canCreateProduct} mt={6} colorScheme="teal" type="submit">
                  Simpan
                </Button>
              </Form>
            </Box>
          </TabPanel>
          <TabPanel>
            <MyProduct />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Product;
