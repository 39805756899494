import { isAxiosError } from 'axios';
import { toast } from 'components/toast';
import { Api } from 'lib/api';

const initialState = {
  produsen_product: [],
  my_product_reseller: [],
};

const createResellerSlice = (set) => ({
  ...initialState,

  addProdusenProduct: async (data) => {
    try {
      const res = await Api.post({
        url: '/admin/reseller/add-product?product=' + data.product,
        data: {
          dropship_commission: data.dropship_commission,
        },
        withCredentials: true,
      });

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  getMyResellerProduct: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/reseller/my-product?' + query);

      set((s) => ({ ...s, my_product_reseller: res }));
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getForResellerProduct: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/product/for-reseller?' + query);

      set((s) => ({ ...s, produsen_product: res }));
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
});

export default createResellerSlice;
