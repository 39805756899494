import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Flex,
  Table as TableComponent,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useMemo } from 'react';
import Card from 'components/card/Card';
import PaginationHandler from './components/PaginationHandler';

const Table = (props) => {
  const { columns, data, showAll, setPagination, pagination } = props;
  const tData = useMemo(() => data ?? [], [data]);
  const tColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: tData ?? [{}],
    columns: tColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: pagination?.totalPages || 0,
    manualPagination: true,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  const renderTableRow = () => {
    if (table.getRowModel().rows.length > 0) {
      return table.getRowModel().rows.map((row) => (
        <Tr key={row._id}>
          {row.getVisibleCells().map((cell) => {
            const meta = cell.column.columnDef.meta;
            return (
              <Td key={cell._id} isNumeric={meta?.isNumeric}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            );
          })}
        </Tr>
      ));
    }
    return (
      <Tr>
        <Td textAlign={'center'} colSpan={table.getAllColumns().length}>
          Data Tidak Tersedia
        </Td>
      </Tr>
    );
  };

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card direction="column" w="100%" px="0px">
      <TableContainer width={'100%'}>
        <TableComponent variant="simple" color="gray.500" mb="24px">
          <Thead outlineColor={'black'}>
            {table.getHeaderGroups().map((headerGroup, index) => {
              return (
                <>
                  <Tr key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        // {...column.getHeaderProps(column.getSortByToggleProps())}
                        pe="10px"
                        key={index}
                        onClick={column.column.getToggleSortingHandler()}
                        isNumeric={column.column.columnDef.meta?.isNumeric}
                        borderColor={borderColor}
                      >
                        <Flex justify="start" align="start" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
                          {column.column.columnDef.header}
                        </Flex>

                        <chakra.span pl="4">
                          {column.column.getIsSorted() &&
                            (column.column.getIsSorted() === 'desc' ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            ))}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                </>
              );
            })}
          </Thead>
          <Tbody>{renderTableRow()}</Tbody>
        </TableComponent>
      </TableContainer>
      {/* </TableContainer> */}
      {/* </Box> */}
      {!showAll && <PaginationHandler table={table} />}
    </Card>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  showAll: PropTypes.bool,
  setPagination: PropTypes.func,
  pagination: PropTypes.object,
};

export default Table;
