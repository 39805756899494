/* eslint-disable unicorn/new-for-builtins */
import { Button, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { lIcons } from 'components/icons/Icons';

const PaginationHandler = ({ table }) => {
  return (
    <Flex mt={5} justifyContent={'center'}>
      <Button
        type="button"
        px={3}
        py={2}
        fontSize={'xs'}
        onClick={() => table?.setPageIndex(0)}
        disabled={!table?.getCanPreviousPage()}
      >
        Pertama
      </Button>
      <Button
        type="button"
        className="px-3 py-2 disabled:text-black/30"
        onClick={() => table?.previousPage()}
        disabled={!table?.getCanPreviousPage()}
      >
        <lIcons.chevronLeft className="w-4" />
      </Button>
      {[...Array(table?.getState()?.pagination?.totalPages)].map((_, index_) => (
        <Button
          key={index_}
          type="button"
          fontSize={'xs'}
          rounded={'lg'}
          disabled={
            table?.getState()?.pagination?.totalPages === table?.getState()?.pagination?.pageIndex ||
            table?.getState()?.pagination?.pageIndex === index_
          }
          onClick={() => table?.setPageIndex(index_)}
        >
          {index_ + 1}
        </Button>
      ))}
      <Button type="button" px={3} py={2} onClick={() => table?.nextPage()} disabled={!table?.getCanNextPage()}>
        <lIcons.chevronRight className="w-4" />
      </Button>
      <Button
        type="button"
        px={3}
        py={2}
        fontSize={'xs'}
        onClick={() => table?.setPageIndex(table?.getPageCount() - 1)}
        disabled={!table?.getCanNextPage()}
      >
        Terakhir
      </Button>
    </Flex>
  );
};

PaginationHandler.propTypes = {
  table: PropTypes.object.isRequired,
};

export default PaginationHandler;
