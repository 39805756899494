import { isAxiosError } from 'axios';
import { toast } from 'components/toast';
import { Api } from 'lib/api';

const initialState = {
  sharing_profit: {
    member_sharing_profit: {
      value: 10,
      type: '',
    },
    my_sharing_profit: {
      value: 10,
      type: 'number',
    },
  },
};

const createSharingProfitSlice = (set) => ({
  ...initialState,
  getSharingProfitDetail: async (community_id) => {
    try {
      const query = new URLSearchParams({
        community_id,
      });
      const res = await Api.get('/admin/sharing-profit/get-detail?' + query);

      if (res?.data) {
        return res;
      }
      return {
        data: [
          {
            name: '',
            commission: {
              type: 'percentage',
              value: 100,
              min_product_pcs: 100,
            },
          },
        ],
      };
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
  createSharingProfitDetail: async (data, queryData) => {
    try {
      const query = new URLSearchParams(queryData);
      const res = await Api.post({
        url: '/admin/sharing-profit/create?' + query,
        data,
      });
      set((state) => ({ ...state, sharing_profit: res.data }));
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
  createSharingProfitForStore: async (data) => {
    const store_id = data.store_id;
    delete data.store_id;
    try {
      const res = await Api.post({
        url: '/admin/sharing-profit/store?store_id=' + store_id,
        data,
      });
      set((state) => ({ ...state, sharing_profit: res.data }));
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
});

export default createSharingProfitSlice;
