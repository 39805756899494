import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FaChevronLeft } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
// Custom components
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import illustration from 'assets/img/auth/auth.png';
import Footer from 'components/footer/FooterAuth';
import Form from 'components/form';
import { joinCommunityWithoutUserSchema } from 'lib/schema';
import useStore from 'store/useStore';

const SignIn = () => {
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const { getCommunityDetailWithoutAuth, joinCommunityWithEmail, community_detail } = useStore();
  useEffect(() => {
    if (query.get('community_id')) {
      getCommunityDetailWithoutAuth({
        community_id: query.get('community_id'),
      });
    }
  }, [query.get('community_id')]);

  const form = useForm({
    resolver: zodResolver(joinCommunityWithoutUserSchema),
    defaultValues: {
      email: '',
      referral_code: '',
    },
  });

  const onSubmit = async (values) => {
    toast.promise(joinCommunityWithEmail(values), {
      loading: 'Signing in...',
      success: 'Successfully logged in!',
      error: 'Failed to log in.',
    });
  };

  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh',
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        justifyContent="center"
        direction="column"
      >
        <NavLink
          to="/admin"
          style={() => ({
            width: 'fit-content',
            marginTop: '40px',
          })}
        >
          <Flex align="center" ps={{ base: '25px', lg: '0px' }} pt={{ lg: '0px', xl: '0px' }} w="fit-content">
            <Icon as={FaChevronLeft} me="12px" h="13px" w="8px" color="secondaryGray.600" />
            <Text ms="0px" fontSize="sm" color="secondaryGray.600">
              Back to DapatKomisi
            </Text>
          </Flex>
        </NavLink>
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              {query?.type ?? 'Produsen'}
            </Heading>
            <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
              Bergabung Dengan Komunitas {community_detail?.name ?? ''}
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <Form methods={form} onSubmit={onSubmit}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                {...form.register('email')}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="email"
                placeholder="mail@simmmple.com"
                mb="24px"
                fontWeight="500"
                size="lg"
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                Referral Code<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  {...form.register('referral_code')}
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={'text'}
                  variant="auth"
                />
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <NavLink to="/auth/forgot-password">
                  <Text color={textColorBrand} fontSize="sm" fontWeight="500">
                    Butuh Referral Code?
                  </Text>
                </NavLink>
              </Flex>
              <Button type="submit" fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px">
                Gabung Komunitas
              </Button>
            </Form>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
        >
          <Flex
            bg={`url(${community_detail?.image ?? ''})`}
            justify="center"
            align="end"
            w="50%"
            h="50%"
            bgSize="auto"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
          ></Flex>
        </Box>
        <Footer />
      </Flex>
    </Flex>
  );
};

export default SignIn;

// PropTypes
SignIn.propTypes = {
  // Define PropTypes here
};
