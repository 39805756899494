import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  Input,
  FormControl,
  FormLabel,
  AlertIcon,
  Alert,
  Text,
  Textarea,
  Flex,
  Image,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'components/form';
import { joinCommunitySchema } from 'lib/schema';
import useStore from 'store/useStore';

const ModalFormJoinCommunity = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const form = useForm({
    resolver: zodResolver(joinCommunitySchema),
    defaultValues: {
      store_id: '',
      referral_code: '',
    },
  });
  const {
    formState: { errors },
  } = form;
  const { joinCommunity, my_store } = useStore();

  useEffect(
    () => () => {
      if (!isOpen) {
        form.reset({
          store_id: '',
          referral_code: '',
        });
      }
    },
    [isOpen],
  );

  const onSubmit = async (values) => {
    const castValues = {
      ...values,
      store_id: my_store?.[0]._id,
    };

    await joinCommunity(castValues);
    onClose();
  };

  return (
    <Box display={'flex'} flexDirection={'column'} px={10} py={4}>
      <Button alignSelf={'flex-end'} colorScheme="blue" onClick={onOpen}>
        Gabung Komunitas
      </Button>
      <Modal blockScrollOnMount={false} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Gabung Komunitas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box px={'10px'} py={'10px'} mb="10px">
              <Form methods={form} onSubmit={onSubmit}>
                <FormControl mb={4}>
                  <FormLabel>Referral Code</FormLabel>
                  <Input
                    type="text"
                    name="referral_code"
                    placeholder="Referral Code"
                    {...form.register('referral_code')}
                  />
                  <Text mt={2} fontWeight={'bold'} fontSize={'sm'} color="red">
                    *Bergabung dengan komunitas baru akan otomatis membuat Anda keluar dari komunitas sebelumnya.
                  </Text>
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                <Button mt={10} colorScheme="blue" type="submit">
                  Simpan
                </Button>
              </Form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalFormJoinCommunity;
