import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useDisclosure,
  FormErrorMessage,
  Flex,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import useStore from 'store/useStore';

const AddAddressModal = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createAddress } = useStore();

  const onSubmit = async (data) => {
    const castValues = {
      email: data.email,
      name: data.name,
      label: data.label,
      phone_number: data.phone_number,
      address: {
        street: data.street,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
      },
    };
    await createAddress(castValues);
    reset(); // Reset form fields after submission
    onClose(); // Close modal after submit
  };

  return (
    <Flex width={'100%'} p={5} height={'full'}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Add New Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isInvalid={errors.label}>
              <FormLabel>Label</FormLabel>
              <Input name="label" {...register('label', { required: 'Label is required.' })} />
              <FormErrorMessage>{errors.label && errors.label.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.email}>
              <FormLabel>Email</FormLabel>
              <Input name="email" type="email" {...register('email', { required: 'Email is required.' })} />
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.name}>
              <FormLabel>Name</FormLabel>
              <Input name="name" {...register('name', { required: 'Name is required.' })} />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.phone_number}>
              <FormLabel>Phone Number</FormLabel>
              <Input name="phone_number" {...register('phone_number', { required: 'Phone number is required.' })} />
              <FormErrorMessage>{errors.phone_number && errors.phone_number.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.street}>
              <FormLabel>Street</FormLabel>
              <Input name="street" {...register('street', { required: 'Street is required.' })} />
              <FormErrorMessage>{errors.street && errors.street.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.city}>
              <FormLabel>City</FormLabel>
              <Input name="city" {...register('city', { required: 'City is required.' })} />
              <FormErrorMessage>{errors.city && errors.city.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.state}>
              <FormLabel>State</FormLabel>
              <Input name="state" {...register('state', { required: 'State is required.' })} />
              <FormErrorMessage>{errors.state && errors.state.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.postal_code}>
              <FormLabel>Postal Code</FormLabel>
              <Input name="postal_code" {...register('postal_code', { required: 'Postal code is required.' })} />
              <FormErrorMessage>{errors.postal_code && errors.postal_code.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.country}>
              <FormLabel>Country</FormLabel>
              <Input name="country" {...register('country', { required: 'Country is required.' })} />
              <FormErrorMessage>{errors.country && errors.country.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4}>
              <Checkbox name="is_default" {...register('is_default')}>
                Default Address
              </Checkbox>
            </FormControl>

            <FormControl mt={4}>
              <Checkbox name="is_main_address" {...register('is_main_address')}>
                Main Address
              </Checkbox>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save
            </Button>
            <Button
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button
        px={2}
        colorScheme="blue"
        height={'200px'}
        fontSize="sm"
        onClick={() => {
          onOpen();
          /* Function to handle the add address */
        }}
      >
        <Text p={5}> Add Address</Text>
      </Button>
    </Flex>
  );
};

export default AddAddressModal;
