import { isAxiosError } from 'axios';
import { toast } from 'components/toast';
import { Api } from 'lib/api';

const initialState = {
  name: '',
  sub_category: '',
};

const data = {
  categoryList: [initialState],
};

const createCategorySlice = (set) => ({
  ...initialState,
  ...data,
  createLoading: false,
  fetchLoading: true,
  createCategory: async (data, callback) => {
    try {
      set((s) => ({ ...s, createLoading: true }));
      const res = await Api.post({
        url: '/category/create',
        data,
      });
      set((s) => ({ ...s, createLoading: false }));
      callback();
      return res;
    } catch (error) {
      set((s) => ({ ...s, createLoading: false }));
      callback();
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getCategory: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/category?' + query);
      set((s) => ({ ...s, categoryList: res.data, fetchLoading: false }));
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  deleteCategory: async (id, callback) => {
    try {
      const res = await Api.delete(`/category/delete?id=${id}`);
      callback();

      return res;
    } catch (error) {
      callback();
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });

      toast({ type: 'error', message: 'something went wrong' });
    }
  },
});

export default createCategorySlice;
