/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  useColorModeValue,
  Stack,
  Text,
  SimpleGrid,
  Image,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Alert,
  AlertIcon,
  ModalOverlay,
  Modal,
  ModalHeader,
  useDisclosure,
  Input,
  HStack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { NumericFormat } from 'react-number-format';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import RatingStars from 'components/rating';
import PaginationHandler from 'components/table/components/PaginationHandler';
import formatRupiah from 'lib/formatRupiah';
import { resellerSchema } from 'lib/schema';
import useStore from 'store/useStore';
import ModalFormJoinCommunity from '../store/components/ModalFormJoinCommunity';

const ProductCard = ({ product, isDropship, community }) => {
  const { addProdusenProduct, my_store } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenCommunity, onOpen: onOpenCommunity, onClose: onCloseCommunity } = useDisclosure();

  const form = useForm({
    resolver: zodResolver(resellerSchema),
    defaultValues: {
      dropship_commission: {
        type: '',
        value: 0,
      },
    },
  });
  const {
    formState: { errors, isSubmitting },
    watch,
  } = form;
  const validateCommissionValue = (value, type) => {
    if (type === 'percentage' && (value < 0 || value > 100)) {
      return 'Percentage must be between 0 and 100';
    }
    if (type === 'number' && value < 0) {
      return 'Value must be a positive number';
    }
    return true;
  };
  const memberCommissionType = watch('dropship_commission.type');
  const onSubmit = async (values) => {
    try {
      await addProdusenProduct({
        ...values,
        product: product._id,
      });
      onClose();
    } catch (error) {
      toast.error(error.message);
      onClose();
    }
  };
  const addProduct = () => {
    onOpen();
  };

  return (
    <>
      <Box
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        padding={2}
        display={'flex'}
        flexDirection={'column'}
      >
        <Image
          height="200px" // Set the default height for the image
          objectFit="cover" // This will cover the height if the image is taller than 200px
          width="200px" // Make the image responsive to the width of the card
          src={product?.variants[0]?.images[0]}
          alignItems={'center'}
          alignSelf={'center'}
          alt={`Picture of ${product?.title}`}
        />
        <Flex mt={'4'} bg={'green.200'} borderRadius={'10'} p={'2'}>
          <Text color={'green.900'} fontWeight={500} fontSize={'md'}>
            {product?.reseller_commission
              ? product?.reseller_commission.commission.type === 'number'
                ? formatRupiah(product?.reseller_commission.commission.value)
                : `${product?.reseller_commission.commission.value}%`
              : '0'}{' '}
            komisi
          </Text>
        </Flex>

        <Box p="2">
          {/* <Box display="flex" alignItems="baseline">
          {product?.isNew && (
            <Badge borderRadius="full" px="2" colorScheme="teal">
              New
            </Badge>
          )}
        </Box> */}
          <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
            {product?.title}
          </Box>
          <Box>
            {formatRupiah(product?.variants[0]?.price)}
            <Box as="span" color="gray.600" fontSize="sm">
              / unit
            </Box>
          </Box>
          <Box>{product?.variants[0]?.stocks} stocks</Box>

          <Stack align="center" justify="space-between" direction="row" mt={6}>
            <Text fontWeight={800} fontSize={'xl'}>
              {product?.rating ? String(product?.rating).slice(0, 3) : 0} / 5
            </Text>
            <RatingStars rating={product?.rating ? String(product?.rating).slice(0, 3) : 0} />
          </Stack>
        </Box>
        <Button
          onClick={() => {
            isDropship
              ? toast.promise(
                  navigator.clipboard.writeText(`https://dapatkomisi.com/product/${product._id}`),
                  {
                    loading: 'Menyalin Tautan',
                    success: 'Berhasil Menyalin',
                    error: 'Terjadi Kesalahan',
                  },
                  {
                    position: 'bottom-center',
                  },
                )
              : addProduct();
          }}
          mt={2}
          colorScheme="red"
        >
          {isDropship ? 'Salin Tautan' : 'Jadi Reseller'}
        </Button>
      </Box>
      <Modal blockScrollOnMount={false} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atur Komisi Untuk Dropship</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box px={'10px'} py={'10px'} mb="10px">
              <Form methods={form} onSubmit={onSubmit}>
                <HStack mb={4} justifyContent={'center'} alignItems={'center'}>
                  <FormControl>
                    <FormLabel htmlFor="dropship-commission-type">Tipe Komisi Dropshipper</FormLabel>
                    <Select id="dropship-commission-type" {...form.register('dropship_commission.type')}>
                      <option value="percentage">Persenan</option>
                      <option value="number">Angka</option>
                    </Select>
                  </FormControl>

                  <FormControl isInvalid={errors?.dropship_commission}>
                    <FormLabel htmlFor="dropship-commission-value">
                      {memberCommissionType === 'percentage' ? 'Persenan Komisi Reseller (%)' : 'Jumlah Komisi'}
                    </FormLabel>
                    {memberCommissionType === 'percentage' ? (
                      <Input
                        id="dropship-commission-value"
                        type="number"
                        {...form.register('dropship_commission.value', {
                          required: 'This field is required',
                          valueAsNumber: true,
                          validate: (value) => validateCommissionValue(value, memberCommissionType),
                        })}
                      />
                    ) : (
                      <NumericFormat
                        {...form.register('dropship_commission.value', {
                          required: 'This field is required',
                          valueAsNumber: true,
                          validate: (value) => validateCommissionValue(value, memberCommissionType),
                        })}
                        // eslint-disable-next-line unicorn/numeric-separators-style
                        placeholder={formatRupiah(10000)}
                        style={{ marginTop: 3 }}
                        className="input-custom"
                        valueIsNumericString={true}
                        prefix="Rp. "
                        onValueChange={({ floatValue }) => {
                          form.setValue(`dropship_commission.value`, floatValue);
                        }}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                      />
                    )}
                    <FormErrorMessage>
                      {errors?.dropship_commission && errors?.dropship_commission?.message}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
                <Button isLoading={isSubmitting} mt={10} colorScheme="teal" type="submit">
                  Save
                </Button>
              </Form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal blockScrollOnMount={false} onClose={onCloseCommunity} isOpen={isOpenCommunity} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gabung Komunitas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box px={'10px'} py={'10px'} mb="10px">
              <Text align={'center'}>
                Untuk Menjadi Reseller Produk Ini Anda Perlu Bergabung Dengan Komunitas{' '}
                <b>{product?.community?.name}</b>
              </Text>
              <Flex direction={'column'}>
                <Button
                  disabled={!product?.community?.community_admin?.email}
                  onClick={() => {
                    const handleEmailClick = () => {
                      window.location.href = `mailto:${product?.community?.community_admin?.email}`;
                    };

                    handleEmailClick();
                  }}
                  colorScheme="blue"
                  mt={10}
                >
                  Email
                </Button>
                <Button
                  disabled={!product?.community?.community_admin?.phone_number}
                  onClick={() => {
                    const whatsappMessage = `Hallo saya tertarik untuk bergabung di komunitas ${product?.community?.name}`;
                    const handleWhatsAppClick = () => {
                      const url = `https://wa.me/${
                        product?.community?.community_admin?.phone_number
                      }?text=${encodeURIComponent(whatsappMessage)}`;
                      window.open(url, '_blank');
                    };
                    handleWhatsAppClick();
                  }}
                  colorScheme="green"
                  mt={2}
                >
                  WhatsApp
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

ProductCard.propTypes = {
  affliate_id: PropTypes.string,
  affliate: PropTypes.bool,
  isDropship: PropTypes.bool,
  community: PropTypes.bool,
  product: PropTypes.shape({
    _id: PropTypes.string,
    reseller_commission: PropTypes.object,
    title: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    community: PropTypes.object,
    affliate: PropTypes.bool,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        price: PropTypes.number,
        description: PropTypes.string,
        stocks: PropTypes.number,
      }),
    ),
  }),
};

const FindProduct = () => {
  const formSearchProduct = useForm({
    defaultValues: {
      search_affiliate_product: '',
    },
  });

  const { getForResellerProduct, produsen_product } = useStore();
  const {
    formState: { errors },
  } = formSearchProduct;

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
    totalPages: produsen_product?.total_pages,
  });

  const table = useReactTable({
    // data: productListAffiliate.data ?? [{}],
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: produsen_product?.total_pages || 0,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
        totalPages: produsen_product?.total_pages || 0,
      },
    },
    onPaginationChange: setPagination,
  });

  useEffect(() => {
    toast.promise(
      getForResellerProduct({
        page: pageIndex + 1,
        limit: pageSize,
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Produk Reseller',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);

  return (
    <>
      <Form
        methods={formSearchProduct}
        onSubmit={(values) => {
          getForResellerProduct({
            page: pageIndex + 1,
            limit: pageSize,
            ...(values?.search
              ? {
                  search: values.search,
                }
              : {}),
          });
        }}
      >
        <Flex dir="row">
          <FormInput
            propsFormControl={{ mb: 4 }}
            inputProps={{
              ...formSearchProduct.register('search'),
              name: 'search',
              placeholder: 'Search',
            }}
            errors={errors}
            type="TEXT"
          />
          <Button type="submit">
            <AiOutlineSearch />
          </Button>
        </Flex>
      </Form>
      <Flex flexDir={'column'}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing="20px">
          {produsen_product?.data?.map((res, index) => (
            <ProductCard key={index} product={res} />
          ))}
        </SimpleGrid>

        <PaginationHandler table={table} />
      </Flex>
    </>
  );
};
const CommunityProduct = () => {
  const formSearchProduct = useForm({
    defaultValues: {
      search_affiliate_product: '',
    },
  });

  const { getForResellerProduct, produsen_product } = useStore();
  const {
    formState: { errors },
  } = formSearchProduct;

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
    totalPages: produsen_product?.total_pages,
  });

  const table = useReactTable({
    // data: productListAffiliate.data ?? [{}],
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: produsen_product?.total_pages || 0,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
        totalPages: produsen_product?.total_pages || 0,
      },
    },
    onPaginationChange: setPagination,
  });

  useEffect(() => {
    toast.promise(
      getForResellerProduct({
        page: pageIndex + 1,
        limit: pageSize,
        community: true,
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Produk Reseller',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);

  return (
    <>
      <Form
        methods={formSearchProduct}
        onSubmit={(values) => {
          getForResellerProduct({
            page: pageIndex + 1,
            limit: pageSize,
            ...(values?.search
              ? {
                  search: values.search,
                }
              : {}),
          });
        }}
      >
        <Flex dir="row">
          <FormInput
            propsFormControl={{ mb: 4 }}
            inputProps={{
              ...formSearchProduct.register('search'),
              name: 'search',
              placeholder: 'Search',
            }}
            errors={errors}
            type="TEXT"
          />
          <Button type="submit">
            <AiOutlineSearch />
          </Button>
        </Flex>
      </Form>
      <Flex flexDir={'column'}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing="20px">
          {produsen_product?.data?.map((res, index) => (
            <ProductCard community key={index} product={res} />
          ))}
        </SimpleGrid>

        <PaginationHandler table={table} />
      </Flex>
    </>
  );
};

const MyAffiliateProduct = () => {
  const { my_product_reseller, getMyResellerProduct } = useStore();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
    totalPages: my_product_reseller?.total_pages,
  });

  const table = useReactTable({
    // data: productListAffiliate.data ?? [{}],
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: my_product_reseller?.total_pages || 0,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
        totalPages: my_product_reseller?.total_pages || 0,
      },
    },
    onPaginationChange: setPagination,
  });
  useEffect(() => {
    toast.promise(
      getMyResellerProduct({
        pageIndex: 0,
        pageSize: 12,
      }),
      {
        loading: 'Memuat..',
        success: 'Mendapatkan Produk Reseller Saya',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, []);

  return (
    <Flex flexDir={'column'}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing="20px">
        {my_product_reseller?.data?.map((res, index) => (
          <ProductCard isDropship key={index} product={res} />
        ))}
      </SimpleGrid>

      <PaginationHandler table={table} />
    </Flex>
  );
};

const Reseller = () => {
  const { myStore } = useStore();
  useEffect(() => {
    myStore();
  }, []);
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} backgroundColor={'white'}>
      <ModalFormJoinCommunity />
      <Tabs isLazy>
        <TabList>
          <Tab>Cari Produk</Tab>
          <Tab>Produk Komunitas</Tab>
          <Tab>Reseller Oleh Saya</Tab>
        </TabList>
        <TabPanels>
          <TabPanel background={'white'}>
            <Box gap="20px" mb="20px">
              <FindProduct />
            </Box>
          </TabPanel>
          <TabPanel background={'white'}>
            <Box gap="20px" mb="20px">
              <CommunityProduct />
            </Box>
          </TabPanel>
          <TabPanel>
            <MyAffiliateProduct />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Reseller;
