import { Icon } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { BiCategoryAlt } from 'react-icons/bi';
import { FaWallet, FaStore, FaTruck } from 'react-icons/fa';
import { GrTransaction } from 'react-icons/gr';
import { IoSettingsSharp } from 'react-icons/io5';
import { MdHome, MdLock, MdAddShoppingCart, MdCollectionsBookmark } from 'react-icons/md';
import { TbAffiliateFilled } from 'react-icons/tb';
import { Redirect, Route } from 'react-router-dom';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import useStore from 'store/useStore';
import Dropship from 'views/admin/affiliate';
import Category from 'views/admin/category';
import Community from 'views/admin/community';
import CommunityDetail from 'views/admin/community/components/CommunityDetail';
import JoinToCommunity from 'views/admin/community/components/JoinToCommunity';
import Config from 'views/admin/config';
import MainDashboard from 'views/admin/default';
import Order from 'views/admin/order';
import Product from 'views/admin/product';
import Reseller from 'views/admin/reseller';
import Store from 'views/admin/store';
import StoreDetail from 'views/admin/store/components/StoreDetail';
import Wallet from 'views/admin/wallet';
import SignInCentered from 'views/auth/signIn';
import PrivateRoute from './privateroutes';

export const routes = [
  {
    name: 'Menu Utama',
    layout: '/admin',
    path: '/default',
    role: [1, 2, 3, 4],
    sidebar: true,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  // {
  //   name: 'NFT Marketplace',
  //   layout: '/admin',
  //   path: '/nft-marketplace',
  //   icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: 'Data Tables',
  //   layout: '/admin',
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: '/data-tables',
  //   component: DataTables,
  // },
  // {
  //   name: 'Profile',
  //   layout: '/admin',
  //   path: '/profile',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: 'Masuk',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    sidebar: false,
  },
  {
    name: 'Masuk',
    layout: '/auth',
    path: '/join-community',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: JoinToCommunity,
    sidebar: false,
  },
  {
    name: 'Produk',
    layout: '/admin',
    path: '/product',
    role: [1, 2],
    icon: <Icon as={MdAddShoppingCart} width="20px" height="20px" color="inherit" />,
    component: Product,
    sidebar: true,
  },
  {
    name: 'Pesanan',
    layout: '/admin',
    path: '/order',
    role: [1, 2, 3, 4],
    icon: <Icon as={GrTransaction} width="20px" height="20px" color="inherit" />,
    component: Order,
    sidebar: true,
  },

  {
    name: 'Toko',
    layout: '/admin',
    exact: true,
    path: '/store',
    role: [1, 2],
    icon: <Icon as={FaStore} width="20px" height="20px" color="inherit" />,
    component: Store,
    sidebar: true,
  },
  {
    name: 'Toko',
    layout: '/admin',
    exact: true,
    path: '/store/:id',
    role: [1],
    icon: <Icon as={FaStore} width="20px" height="20px" color="inherit" />,
    component: StoreDetail,
    sidebar: false,
  },
  {
    name: 'Kategori',
    layout: '/admin',
    sidebar: true,
    path: '/category',
    role: [1],
    icon: <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />,
    component: Category,
  },
  {
    name: 'Reseller',
    layout: '/admin',
    path: '/reseller',
    role: [1, 3],
    icon: <Icon as={TbAffiliateFilled} width="20px" height="20px" color="inherit" />,
    component: Reseller,
    sidebar: true,
  },
  {
    name: 'Dropship',
    layout: '/admin',
    path: '/dropship',
    icon: <Icon as={FaTruck} width="20px" height="20px" color="inherit" />,
    component: Dropship,
    role: [1, 4],
    sidebar: true,
  },
  {
    name: 'Komunitas',
    layout: '/admin',
    exact: true,
    sidebar: true,
    path: '/community',
    role: [1, 7],
    icon: <Icon as={MdCollectionsBookmark} width="20px" height="20px" color="inherit" />,
    component: Community,
  },
  {
    name: 'Komunitas',
    layout: '/admin',
    exact: true,
    sidebar: false,
    path: '/community/:id',
    role: [1],
    icon: <Icon as={MdCollectionsBookmark} width="20px" height="20px" color="inherit" />,
    component: CommunityDetail,
  },
  {
    name: 'Dompet',
    layout: '/admin',
    sidebar: true,
    role: [1, 2, 3, 4, 7],
    path: '/wallet',
    icon: <Icon as={FaWallet} width="20px" height="20px" color="inherit" />,
    component: Wallet,
  },
  {
    name: 'Pengaturan',
    layout: '/admin',
    sidebar: true,
    path: '/config',
    role: [1],
    icon: <Icon as={IoSettingsSharp} width="20px" height="20px" color="inherit" />,
    component: Config,
  },
];

const Router = () => {
  const { loadUser } = useStore();
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <>
      <Route path={`/auth`} component={AuthLayout} />
      <PrivateRoute path="/admin" component={AdminLayout} />
      <Redirect from={'/'} to="/auth" />
    </>
  );
};

Router.propTypes = {};

export default Router;
