import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Text,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useState } from 'react';

const ModalVariants = ({ isOpen, onOpen, onClose, data }) => {
  const [selectedVariants, setSelectedVariants] = useState(0);
  const [imageSelected, setImageSelected] = useState(0);

  const initialReference = React.useRef(null);
  const finalReference = React.useRef(null);

  return (
    <>
      <Modal
        initialFocusRef={initialReference}
        finalFocusRef={finalReference}
        isOpen={isOpen}
        size="6xl"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent ml={10} mr={10} mt={10} mb={10}>
          <ModalHeader>Variasi</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Flex width={'50%'} align={'center'}>
                <Flex width={'100%'} align={'center'}>
                  <Flex flexDirection={'column'}>
                    {data?.variants?.[selectedVariants]?.images?.map((res, index) => {
                      return (
                        <Image
                          key={index}
                          onClick={() => setImageSelected(index)}
                          src={res}
                          width={'50px'}
                          borderRadius="16px"
                          mb="28px"
                        />
                      );
                    })}
                  </Flex>
                  <Flex justify={'center'} width={'100%'}>
                    <Image
                      src={data?.variants?.[selectedVariants]?.images[imageSelected]}
                      width={'150px'}
                      borderRadius="16px"
                      mb="28px"
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex width={'50%'} flexDirection={'column'}>
                <Text fontWeight={'bold'} color={'gray.300'}>
                  {data?.category?.name}
                </Text>
                <Text fontWeight={'bold'} fontSize={'3xl'}>
                  {data?.variants?.[selectedVariants]?.title}
                </Text>
                <Text fontWeight={'bold'} fontSize={'1xl'}>
                  Rp, {data?.variants?.[selectedVariants]?.price}
                </Text>
                <Text mb={10} fontWeight={'medium'} color={'gray.500'} mt={10} fontSize={'1xl'}>
                  {data?.variants?.[selectedVariants]?.description}
                </Text>
                <Grid
                  mb={10}
                  templateColumns="repeat(auto-fill, minmax(90px, 1fr))" // Auto layout
                  gap={4} // Gap between grid items
                >
                  {data?.variants?.map((res, index) => {
                    return (
                      <GridItem key={index}>
                        <Button
                          colorScheme="green"
                          onClick={() => {
                            setSelectedVariants(index);
                          }}
                        >
                          {`Variasi ${index}`}
                        </Button>
                      </GridItem>
                    );
                  })}
                </Grid>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

ModalVariants.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        price: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default ModalVariants;
