import axios, { isAxiosError } from 'axios';
import { StateCreator } from 'zustand';
import { toast } from 'components/toast';
import { Api } from 'lib/api';
import objectToFormData from 'lib/objectToFormData';

const initialState = {
  c: [],
};

const createStoreSlice = (set) => ({
  ...initialState,
  getStore: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const store = await Api.get('/store?' + query);
      set((state) => ({ ...state, store_list: store }));
    } catch (error) {
      toast({ type: 'error', message: error.data.error });
    }
  },
  getDetailStore: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const store = await Api.get('/store/detail?' + query);
      set((state) => ({ ...state, my_store: [store.data] }));
    } catch (error) {
      toast({ type: 'error', message: error.data.error });
    }
  },
  getStoreAddress: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const address = await Api.get('/store/address?' + query);
      set((state) => ({ ...state, address: address }));
    } catch (error) {
      toast({ type: 'error', message: error.data.error });
    }
  },
});

export default createStoreSlice;
