import { isAxiosError } from 'axios';
import toast from 'react-hot-toast';
import { Api } from 'lib/api';
import objectToFormData from 'lib/objectToFormData';

const initialState = {
  name: '',
  sub_community: '',
  community_detail: {},
  store_by_community: [],
  community_dashboard: {},
};

const data = {
  communityList: [initialState],
};

const createCommunitySlice = (set) => ({
  ...initialState,
  ...data,
  createLoading: false,
  fetchLoading: true,
  createCommunity: async (data, callback) => {
    try {
      set((s) => ({ ...s, createLoading: true }));
      const formData = new FormData();
      objectToFormData(data, formData);
      const res = await Api.post({
        url: '/admin/community/create',
        data: formData,
      });
      set((s) => ({ ...s, createLoading: false }));
      callback();
      return res;
    } catch (error) {
      set((s) => ({ ...s, createLoading: false }));
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  getCommunity: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/community/list?' + query);
      set((s) => ({ ...s, communityList: res, createLoading: false }));
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  getCommunityDetailWithoutAuth: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/community/detail?' + query);
      set((s) => ({ ...s, community_detail: res, createLoading: false }));
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  joinCommunityWithEmail: async (data) => {
    try {
      const res = await Api.post({
        url: '/store/join/',
        data,
        withCredentials: true,
      });
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  getCommunityDashboard: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/community/dashboard?' + query);
      set((s) => ({ ...s, community_dashboard: res }));
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  updateStatusStore: async (data) => {
    try {
      await Api.post({
        url: '/admin/community/store?',
        data,
        withCredentials: true,
      });
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  getStoreByCommunity: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();

      const res = await Api.get('/admin/community/store?' + query);
      set((s) => ({ ...s, store_by_community: res }));
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  getMyCommunity: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/community/my?' + query);
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  updateCommunity: async (id, data) => {
    try {
      const formData = new FormData();
      objectToFormData(data, formData);
      const res = await Api.post({
        url: '/admin/community/update?id=' + id,
        data: formData,
        withCredentials: true,
      });
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  joinCommunity: async (data) => {
    try {
      const res = await Api.post({
        url: '/store/join/',
        data,
        withCredentials: true,
      });
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  removeBannerCommunity: async (id, data) => {
    try {
      const res = await Api.post({
        url: '/admin/community/delete-banner?id=' + id,
        data,
        withCredentials: true,
      });
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
  deleteCommunity: async (id, callback) => {
    try {
      const res = await Api.delete(`/admin/community/delete?id=${id}`);
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || error?.message || 'something went wrong');
    }
  },
});

export default createCommunitySlice;
