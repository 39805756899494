import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import CategoryDigital from './components/CategoryDigital';
import CategoryList from './components/CategoryList';
import CategoryNonDigital from './components/CategoryNonDigital';
import ModalForm from './components/ModalForm';

const Category = () => {
  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      display={'flex'}
      flexDirection={'column'}
      backgroundColor={'white'}
    >
      <ModalForm />

      <Tabs isLazy>
        <TabList>
          <Tab>Digital</Tab>
          <Tab>Non Digital</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CategoryDigital />
          </TabPanel>
          <TabPanel>
            <CategoryNonDigital />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Category;
