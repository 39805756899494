const objectToFormData = (data, formData, parentKey) => {
  for (const key in data) {
    const value = data[key];
    const keyName = parentKey ? `${parentKey}[${key}]` : key;

    if (typeof value === 'object' && !Array.isArray(value) && key !== 'files' && key !== 'file') {
      if (Object.keys(value).length === 0) {
        // If the object is empty, just append the key with an empty value
        formData.append(keyName, '');
      } else {
        // If the value is an object, but not empty, recursively process it
        objectToFormData(value, formData, keyName);
      }
    } else if (Array.isArray(value)) {
      if (key === 'banner') {
        value.forEach((file, index) => {
          formData.append(`banner`, file);
        });
      } else {
        // If the value is an array, loop through its elements and recursively process them
        for (const [index, element] of value.entries()) {
          const arrayKeyName = `${keyName}[${index}]`;
          objectToFormData(element, formData, arrayKeyName);
        }
      }
    } else {
      if ((key === 'files' && parentKey === 'files') || (key === 'file' && parentKey === 'file')) {
        // Handle file data separately by creating a Blob from the base64 data
        formData.append('files', value);
      } else {
        formData.append(keyName, value);
      }
    }
  }
};
export default objectToFormData;
