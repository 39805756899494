import { Parser } from '@json2csv/plainjs';

const toCsv = (data) => {
  try {
    const options = {};
    const parser = new Parser(options);
    const csv = parser.parse(data);

    return csv;
  } catch (error) {
    console.error(error);
  }
};

export default toCsv;
