import PropTypes from 'prop-types';
import React from 'react';
import hotToast from 'react-hot-toast';

const Toast = ({ visible, className, ...props }) => {
  return <div {...props} />;
};

Toast.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
};

Toast.Title = ({ className, ...props }) => {
  return <p {...props} />;
};

Toast.Title.propTypes = {
  className: PropTypes.string,
};

Toast.Description = ({ className, ...props }) => {
  return <p {...props} />;
};

Toast.Description.propTypes = {
  className: PropTypes.string,
};

const toast = (options) => {
  const { title, message, duration = 3000 } = options;

  return hotToast.custom(
    ({ visible }) => (
      <Toast visible={visible}>
        <Toast.Title>{title}</Toast.Title>
        {message && <Toast.Description>{message}</Toast.Description>}
      </Toast>
    ),
    { duration },
  );
};

toast.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    type: PropTypes.string,
    duration: PropTypes.number,
  }).isRequired,
};
export { toast };

export { Toaster } from 'react-hot-toast';
