import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  Input,
  FormControl,
  FormLabel,
  AlertIcon,
  Alert,
  Text,
  Textarea,
  Flex,
  Image,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import imageUpload from 'assets/img/form/upload-image.png';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import { communitySchema } from 'lib/schema';
import useStore from 'store/useStore';

const ModalForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imagePreview, setImagePreview] = useState(null);
  const [showRemove, setShowRemove] = useState(false);
  const form = useForm({
    resolver: zodResolver(communitySchema),
    defaultValues: {
      name: '',
      desc: '',
      email_admin: '',
      phone_number_admin: '',
      video: '',
      notif_member: '',
      name_admin: '',
      file: null,
      banner: [
        {
          file: null,
          preview: null,
        },
      ],
    },
  });
  const {
    formState: { errors },
  } = form;
  const [createCommunityHandler, createLoading, getCommunityHandler] = useStore((s) => [
    s.createCommunity,
    s.createLoading,
    s.getCommunity,
  ]);
  const fileInputReference = useRef(null);

  useEffect(
    () => () => {
      if (!isOpen) {
        form.reset();
      }
    },
    [isOpen],
  );
  const displayImagePreview = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    form.setValue(`file`, file);
    displayImagePreview(file);
  };

  const onSubmitFinished = () => {
    onClose();
    getCommunityHandler();
  };

  const onSubmit = async (values) => {
    const castValues = {
      ...values,
      banner: values.banner.map((res) => res.file),
    };

    await createCommunityHandler(castValues, onSubmitFinished);
  };
  const handleDrop = (e, fileType) => {
    e.preventDefault();
    // Implement the logic for handling the dropped image here
  };

  const openFileExplorer = () => {
    // Trigger a click event on the hidden file input to open the file explorer
    fileInputReference.current.click();
  };
  return (
    <Box display={'flex'} flexDirection={'column'} px={10} py={4}>
      <Button alignSelf={'flex-end'} colorScheme="blue" onClick={onOpen}>
        Tambah Komunitas
      </Button>
      <Modal scrollBehavior="outside" onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tambah Komunitas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form methods={form} onSubmit={onSubmit}>
              <FormControl mb={4} onDragOver={(e) => e.preventDefault()}>
                <FormLabel>Gambar Komunitas</FormLabel>
                <Box
                  htmlFor="image-upload"
                  cursor="pointer"
                  w="100%"
                  border="2px dashed #cbd5e0"
                  borderRadius="md"
                  padding={4}
                  textAlign="center"
                  _hover={{ borderColor: 'teal.500' }}
                  onDrop={(e) => handleDrop(e, 'image')}
                  justifyContent={'center'}
                  alignItems={'center'}
                  display={'flex'}
                  onMouseEnter={() => setShowRemove(true)}
                  onMouseLeave={() => setShowRemove(false)}
                  onClick={() => (imagePreview ? undefined : openFileExplorer())}
                >
                  {form.getValues(`file`) ? (
                    <Flex width={'100%'} alignItems={'center'} justifyContent={'center'} position={'relative'}>
                      {showRemove && (
                        <Flex
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          justifyContent={'center'}
                          alignItems={'center'}
                          position={'absolute'}
                          flexDirection={'row'}
                        >
                          <Button
                            onClick={() => {
                              form.resetField(`file`);
                              setImagePreview(null);
                            }}
                            size="sm"
                          >
                            Remove
                          </Button>
                        </Flex>
                      )}

                      <Image maxHeight={150} objectFit={'cover'} src={imagePreview} borderRadius="16px" />
                    </Flex>
                  ) : (
                    <Image maxHeight={150} objectFit={'cover'} src={imageUpload} borderRadius="16px" />
                  )}
                </Box>
                <Input
                  {...form.register(`file`)}
                  ref={fileInputReference}
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />
                <ErrorMessage
                  errors={errors}
                  name={`file`}
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormInput
                propsFormControl={{ mb: 4 }}
                label="Banner Komunitas"
                inputProps={{
                  ...form.register('banner'),
                  name: 'banner',
                  placeholder: 'Banner',
                  form: form,
                  multiple: true,
                }}
                errors={errors}
                type="IMAGE"
              />
              <FormControl mb={4}>
                <FormLabel>Nama Komunitas</FormLabel>
                <Input type="text" name="name" placeholder="Nama Komunitas" {...form.register('name')} />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Notif Member</FormLabel>
                <Input type="text" name="name" placeholder="Notif Member" {...form.register('notif_member')} />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Deskripsi</FormLabel>
                <Textarea {...form.register('desc')} name="desc" placeholder="Description" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>

              <FormControl mb={4}>
                <FormLabel>Tautan Video</FormLabel>
                <Input {...form.register('video')} name="video" placeholder="Video" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="video"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Alamat</FormLabel>
                <Textarea {...form.register('address')} name="address" placeholder="Address" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="address"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Nama Admin</FormLabel>
                <Input {...form.register('name_admin')} name="name_admin" placeholder="Name Admin" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="name_admin"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Email Admin</FormLabel>
                <Input {...form.register('email_admin')} name="email_admin" placeholder="Email Admin" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="email_admin"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Nomor Telfon Admin</FormLabel>
                <Input
                  {...form.register('phone_number_admin')}
                  name="phone_number_admin"
                  placeholder="Phone Number Admin"
                  resize={'none'}
                />
                <ErrorMessage
                  errors={errors}
                  name="phone_number_admin"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>

              <Button isLoading={createLoading} mt={10} colorScheme="teal" type="submit">
                Simpan
              </Button>
            </Form>
            {/* </Box> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalForm;
