import axios, { isAxiosError } from 'axios';
import { StateCreator } from 'zustand';
import { toast } from 'components/toast';
import { Api } from 'lib/api';

const initialState = {
  address: [],
};

const createAuthSlice = (set) => ({
  ...initialState,
  createAddress: async (data) => {
    try {
      await Api.post({ url: 'address/create', data });
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
  getAddress: async () => {
    try {
      const res = await Api.get('address');
      set((state) => ({ ...state, address: res.data }));
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
  setMainAddress: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      await Api.post({ url: 'address/set-main-address?' + query });
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
});

export default createAuthSlice;
