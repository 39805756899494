/* eslint-disable no-nested-ternary */
import { useDisclosure, Button, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteDialog from 'components/deleteDialog';
import Table from 'components/table';
import useStore from 'store/useStore';
import ModalUpdateProduct from './ModalUpdateProduct';
import ModalVariants from './ModalVariants';

const MyProduct = ({ store_id, community_id, isReseller, isProdusen }) => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const pageParameter = query?.get('page');
  const [variantsData, setVariantsData] = useState({});
  const limitParameter = query?.get('limit');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const modalDisclosure = useDisclosure();
  const modalUpdate = useDisclosure();
  const { onOpen } = modalDisclosure;
  const [productList, getProductHandler, deleteProduct, user] = useStore((s) => [
    s.productList,
    s.getProduct,
    s.deleteProduct,
    s.user,
  ]);

  const columns = [
    { header: 'Nama', accessorKey: 'title' },
    //react table if average_weight is null, it will return 0
    {
      header: 'Kategori',
      accessorKey: 'category',
      cell: (data) => data.getValue()?.name ?? '-',
    },
    {
      header: 'Ulasan',
      accessorKey: 'rating',
      cell: (data) => data.getValue() ?? '-',
    },
    {
      header: 'Deskripsi',
      accessorKey: 'description',
      cell: (data) => <Text whiteSpace={'break-spaces'}>{data.getValue() ?? '-'}</Text>,
    },
    {
      header: 'Variasi',
      accessorKey: 'category',
      cell: (data) => (
        <Button
          onClick={() => {
            setVariantsData(productList.data[data.row.index]);
            onOpen();
          }}
          colorScheme="green"
          variant="outline"
          size="md"
          mr={2}
        >
          Detail
        </Button>
      ),
    },
  ];

  if (!store_id && user.role === 2) {
    columns.push({
      header: 'Aksi',
      accessorKey: '_id',
      cell: (data) => (
        <div className="flex gap-2">
          <Button
            onClick={() => {
              setVariantsData(productList.data[data.row.index]);
              modalUpdate.onOpen();
            }}
            colorScheme="blue"
            size="md"
            mr={2}
          >
            Ubah
          </Button>
          <Flex height={2} />
          <DeleteDialog
            title={`Hapus Produk`}
            desc={`Anda Yakin Ingin Melakukan Ini?`}
            handler={(callback) => deleteProduct(data.getValue(), callback)}
          />
        </div>
      ),
    });
  }

  useEffect(() => {
    if (pageParameter) {
      setPagination((res) => ({
        ...res,
        pageIndex: Number(pageParameter),
        pageSize: Number(limitParameter),
      }));
    }
  }, [JSON.stringify(pageParameter)]);

  useEffect(() => {
    toast.promise(
      getProductHandler({
        page: pageIndex + 1,
        limit: pageSize,
        ...(store_id ? { store_id } : community_id ? {} : { my_product: true }),
        ...(community_id ? { community_id } : {}),
        ...(isReseller ? { reseller: true } : {}),
        ...(isProdusen ? { produsen: true } : {}),
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Produk',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize, store_id]);

  return (
    <>
      <Table
        pagination={{
          pageIndex,
          pageSize,
          totalPages: productList?.total_pages,
        }}
        setPagination={setPagination}
        data={productList?.data}
        columns={columns}
      />
      <ModalVariants {...modalDisclosure} data={variantsData} />
      <ModalUpdateProduct {...modalUpdate} data={variantsData} />
    </>
  );
};

MyProduct.propTypes = {
  isReseller: PropTypes.string,
  isProdusen: PropTypes.string,
  store_id: PropTypes.string,
  community_id: PropTypes.string,
};
export default MyProduct;
