const formatRupiah = (number) => {
  if (typeof number !== 'number') {
    return '-';
  }

  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
};

export default formatRupiah;
