/* eslint-disable no-nested-ternary */
import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Button } from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useMemo } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
// Custom components
import Card from 'components/card/Card';
import useStore from 'store/useStore';

const ColumnsTable = (props) => {
  const { columnsData } = props;
  const { getTopFiveSellingProduct, top_selling_product } = useStore();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => top_selling_product.data || [], [JSON.stringify(top_selling_product)]);
  useEffect(() => getTopFiveSellingProduct(), []);

  const tableInstance = useTable(
    {
      columns,
      data: data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance;
  initialState.pageSize = 5;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space between" mb="10px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Lima Produk Terlaris
        </Text>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex justify="space between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = '';
                  switch (cell.column.Header) {
                    case 'NAMA PRODUK': {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );

                      break;
                    }
                    case 'TERJUAL': {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );

                      break;
                    }
                    case 'PRODUCT DETAIL': {
                      data = (
                        <Button onClick={() => window.open(`https://dapatkomisi.com/product/${cell.value}`)}>
                          detail
                        </Button>
                      );

                      break;
                    }
                    // No default
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: '14px' }}
                      maxH="30px !important"
                      py="8px"
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
};

export default ColumnsTable;

// PropTypes
ColumnsTable.propTypes = {
  columnsData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
};
