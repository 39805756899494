import { Box, Flex, Text, VStack, Heading, Switch } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import useStore from 'store/useStore';

const AddressCard = ({ address, id, isActive, onToggleActive }) => {
  const { setMainAddress } = useStore();

  return (
    <Box bg="white" boxShadow="md" borderRadius="lg" p={5} m={5} height={'200px'} minW="sm" borderWidth="1px">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h3" size="lg" mb={4}>
          Alamat Toko
        </Heading>
        <Switch
          id="toggle-switch"
          colorScheme="teal"
          isChecked={isActive}
          onChange={async (e) => {
            if (e.target.checked) {
              await setMainAddress({ address_id: id });
              onToggleActive(id);
            }
          }}
          size="lg"
        />
      </Flex>
      <VStack alignItems="flex-start" spacing={1}>
        <Text fontSize="md" fontWeight="semibold">
          ALAMAT
        </Text>
        <Text fontSize="sm">{address}</Text>
      </VStack>
    </Box>
  );
};

AddressCard.propTypes = {
  address: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  onToggleActive: PropTypes.func,
};

export default AddressCard;
