import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  Button,
  Image,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteDialog from 'components/deleteDialog';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import Table from 'components/table';
import { communitySchema, sharingProfitSchema, updateSharingProfitSchema } from 'lib/schema';
import useStore from 'store/useStore';
import MyProduct from 'views/admin/product/components/MyProduct';

const CommunityStore = ({ isReseller, isProdusen }) => {
  const location = useLocation();
  const { id } = useParams();
  const query = new URLSearchParams(location?.search);
  const pageParameter = query?.get('page');
  const limitParameter = query?.get('limit');
  const { getStoreByCommunity, store_by_community, user, getSharingProfitDetail } = useStore();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = [
    { header: 'Store Name', accessorKey: 'name' },
    //react table if average_weight is null, it will return 0
    {
      header: 'Rating',
      accessorKey: 'rating',
      cell: (data) => data.getValue() ?? '-',
    },
    {
      header: 'Image',
      accessorKey: 'image',
      cell: (data) => <Image maxHeight={150} objectFit={'cover'} src={data?.getValue()} borderRadius="16px" />,
    },
  ];

  useEffect(() => {
    if (pageParameter) {
      setPagination((res) => ({
        ...res,
        pageIndex: Number(pageParameter),
        pageSize: Number(limitParameter),
      }));
    }
  }, [JSON.stringify(pageParameter)]);

  useEffect(() => {
    toast.promise(
      getStoreByCommunity({
        community_id: user?.admin_of_community || id,
        reseller: isReseller,
        produsen: isProdusen,
        page: pageIndex + 1,
        limit: pageSize,
        status: 'approve',
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Data Komunitas',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);

  return (
    <>
      <Table
        pagination={{
          pageIndex,
          pageSize,
          totalPages: store_by_community?.total_pages,
        }}
        setPagination={setPagination}
        data={store_by_community?.data}
        columns={columns}
      />
    </>
  );
};

CommunityStore.propTypes = {
  isReseller: PropTypes.bool,
  isProdusen: PropTypes.bool,
};
CommunityStore.defaultProps = {
  isReseller: false,
  isProdusen: false,
};
const CommunityRequest = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location?.search);
  const pageParameter = query?.get('page');
  const limitParameter = query?.get('limit');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { getStoreByCommunity, store_by_community, user, updateStatusStore } = useStore();

  const columns = [
    { header: 'Store Name', accessorKey: 'name' },
    //react table if average_weight is null, it will return 0
    {
      header: 'Rating',
      accessorKey: 'rating',
      cell: (data) => data.getValue() ?? '-',
    },
    {
      header: 'Image',
      accessorKey: 'image',
      cell: (data) => <Image maxHeight={150} objectFit={'cover'} src={data?.getValue()} borderRadius="16px" />,
    },
    {
      header: 'aksi',
      accessorKey: '_id',
      cell: (data) => (
        <div className="flex gap-2">
          <DeleteDialog
            title={`Terima`}
            colorScheme={'green'}
            desc={`Apakah Anda Yakin?`}
            handler={async () => {
              await updateStatusStore({
                store_id: data.getValue(),
                community_id: id,
                status: 'approve',
              });
              history.go();
            }}
          />
          <Flex width={2} height={2} />
          <DeleteDialog
            title={`Tolak`}
            colorScheme={'red'}
            desc={`Apakah Anda Yakin?`}
            handler={async () => {
              await updateStatusStore({
                store_id: data.getValue(),
                status: 'reject',
              });
              history.go();
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (pageParameter) {
      setPagination((res) => ({
        ...res,
        pageIndex: Number(pageParameter),
        pageSize: Number(limitParameter),
      }));
    }
  }, [JSON.stringify(pageParameter)]);

  useEffect(() => {
    toast.promise(
      getStoreByCommunity({
        community_id: user?.admin_of_community || id,
        page: pageIndex + 1,
        limit: pageSize,
        status: 'pending',
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Data Komunitas',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);

  return (
    <Table
      pagination={{
        pageIndex,
        pageSize,
        totalPages: store_by_community?.total_pages,
      }}
      setPagination={setPagination}
      data={store_by_community?.data}
      columns={columns}
    />
  );
};
const CommissionSettings = () => {
  const { id } = useParams();
  const { getSharingProfitDetail, createSharingProfitDetail, user } = useStore();
  const form = useForm({
    resolver: zodResolver(sharingProfitSchema),
    defaultValues: async () =>
      (await getSharingProfitDetail(id ?? user.admin_of_community)) || {
        data: [
          {
            name: '',
            commission: {
              type: 'percentage',
              value: 100,
              min_product_pcs: 100,
            },
          },
        ],
      },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;

  const dataForm = watch('data');

  const validateCommissionValue = (value, { type }) => {
    if (type === 'percentage' && (value < 0 || value > 100)) {
      return 'Percentage must be between 0 and 100';
    }
    if (type === 'number' && value < 0) {
      return 'Value must be a positive number';
    }
    return true;
  };

  const onSubmit = async (data) => {
    toast.promise(
      createSharingProfitDetail(data, { community_id: id }),
      {
        loading: 'Loading',
        success: 'Commission Setting is updated',
        error: 'Error when updating',
      },
      {
        position: 'bottom-center',
      },
    );
  };

  const addMoreTier = () => {
    const data = form.getValues('data');
    form.setValue('data', [
      ...data,
      {
        name: '',
        commission: {
          type: 'percentage',
          value: 100,
          min_product_pcs: 100,
        },
      },
    ]);
  };

  return (
    <Box p={4}>
      <Heading mb={6}>Pengaturan Komisi</Heading>
      <Form methods={form} onSubmit={onSubmit}>
        <Stack spacing={4}>
          {dataForm?.map((res, index) => (
            <>
              <FormControl>
                <FormLabel htmlFor={`data.${index}.name`}>Name</FormLabel>
                <Input
                  id={`data.${index}.name`}
                  type="text"
                  {...register(`data.${index}.name`, {
                    required: 'This field is required',
                  })}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`data.${index}.commission.min_product_pcs`}>Minimal Produk Per Pcs</FormLabel>
                <Input
                  id={`data.${index}.commission.min_product_pcs`}
                  type="number"
                  {...register(`data.${index}.commission.min_product_pcs`, {
                    required: 'This field is required',
                    valueAsNumber: true,
                  })}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={`data.${index}.type`}>Tipe Komisi</FormLabel>
                <Select id={`data.${index}.commission.type`} {...register(`data.${index}.commission.type`)}>
                  <option value="percentage">Persenan</option>
                  <option value="number">Angka</option>
                </Select>
              </FormControl>

              <FormControl isInvalid={errors.communityCommissionValue}>
                <FormLabel htmlFor={`data.${index}.commission.value`}>
                  {dataForm[index].commission.type === 'percentage' ? 'Persenan Komisi(%)' : 'Jumlah Komisi'}
                </FormLabel>
                <Input
                  id={`data.${index}.commission.value`}
                  type="number"
                  {...register(`data.${index}.commission.value`, {
                    required: 'This field is required',
                    valueAsNumber: true,
                    validate: (value) => validateCommissionValue(value, dataForm[index].commission),
                  })}
                />
                <FormErrorMessage>
                  {errors.communityCommissionValue && errors.communityCommissionValue.message}
                </FormErrorMessage>
              </FormControl>
            </>
          ))}
          <Button disabled={user?.role === 7} colorScheme="green" onClick={addMoreTier} type="button">
            Tambah Tier
          </Button>
          <Button disabled={user?.role === 7} colorScheme="blue" type="submit">
            Simpan
          </Button>
        </Stack>
      </Form>
    </Box>
  );
};
// const ProductCommunity = ({ store_id }) => {
//   const location = useLocation();
//   const query = new URLSearchParams(location?.search);
//   const pageParameter = query?.get('page');
//   const [variantsData, setVariantsData] = useState({});
//   const limitParameter = query?.get('limit');
//   const [{ pageIndex, pageSize }, setPagination] = useState({
//     pageIndex: 0,
//     pageSize: 10,
//   });
//   const modalDisclosure = useDisclosure();
//   const modalUpdate = useDisclosure();
//   const { onOpen } = modalDisclosure;
//   const [productList, getProductHandler, deleteProduct] = useStore((s) => [
//     s.productList,
//     s.getProduct,
//     s.deleteProduct,
//   ]);

//   const columns = [
//     { header: 'Nama', accessorKey: 'title' },
//     //react table if average_weight is null, it will return 0
//     {
//       header: 'Kategori',
//       accessorKey: 'category',
//       cell: (data) => data.getValue()?.name ?? '-',
//     },
//     {
//       header: 'Ulasan',
//       accessorKey: 'rating',
//       cell: (data) => data.getValue() ?? '-',
//     },
//     {
//       header: 'Deskripsi',
//       accessorKey: 'description',
//       cell: (data) => data.getValue() ?? '-',
//     },
//     {
//       header: 'Variasi',
//       accessorKey: 'category',
//       cell: (data) => (
//         <Button
//           onClick={() => {
//             setVariantsData(productList.data[data.row.index]);
//             onOpen();
//           }}
//           colorScheme="green"
//           variant="outline"
//           size="md"
//           mr={2}
//         >
//           Detail
//         </Button>
//       ),
//     },
//   ];

//   if (!store_id) {
//     columns.push({
//       header: 'Aksi',
//       accessorKey: '_id',
//       cell: (data) => (
//         <div className="flex gap-2">
//           <Button
//             onClick={() => {
//               setVariantsData(productList.data[data.row.index]);
//               modalUpdate.onOpen();
//             }}
//             colorScheme="blue"
//             size="md"
//             mr={2}
//           >
//             Ubah
//           </Button>
//           <Flex height={2} />
//           <DeleteDialog
//             title={`Hapus Produk`}
//             desc={`Anda Yakin Ingin Melakukan Ini?`}
//             handler={(callback) => deleteProduct(data.getValue(), callback)}
//           />
//         </div>
//       ),
//     });
//   }

//   useEffect(() => {
//     if (pageParameter) {
//       setPagination((res) => ({
//         ...res,
//         pageIndex: Number(pageParameter),
//         pageSize: Number(limitParameter),
//       }));
//     }
//   }, [JSON.stringify(pageParameter)]);

//   useEffect(() => {
//     toast.promise(
//       getProductHandler({
//         page: pageIndex + 1,
//         limit: pageSize,
//         ...(store_id ? { store_id } : {}),
//       }),
//       {
//         loading: 'Memuat...',
//         success: 'Mendapatkan Produk',
//         error: 'Terjadi Kesalahan',
//       },
//       {
//         position: 'bottom-center',
//       },
//     );
//   }, [pageIndex, pageSize, store_id]);

//   return (
//     <>
//       <Table
//         pagination={{
//           pageIndex,
//           pageSize,
//           totalPages: productList?.total_pages,
//         }}
//         setPagination={setPagination}
//         data={productList?.data}
//         columns={columns}
//       />
//       <ModalVariants {...modalDisclosure} data={variantsData} />
//       <ModalUpdateProduct {...modalUpdate} data={variantsData} />
//     </>
//   );
// };

const CommunityDetail = () => {
  const { id } = useParams();
  const { getMyCommunity, user } = useStore();
  const form = useForm({
    resolver: zodResolver(communitySchema),
    defaultValues: async () => await getMyCommunity({ community_id: id ?? user?.admin_of_community }),
  });

  const {
    formState: { errors },
  } = form;

  return (
    <Box
      pt={{ base: id ? '130px' : 0, md: id ? '80px' : 0, xl: id ? '80px' : 0 }}
      display={'flex'}
      flexDirection={'column'}
      backgroundColor={'white'}
    >
      <Flex borderRadius={'2xl'} px={10} py={10} flexDirection={'column'} backgroundColor={'white'}>
        <FormInput
          propsFormControl={{ mb: 4 }}
          label="Community Name"
          inputProps={{
            ...form.register('name'),
            name: 'name',
            placeholder: 'Name',
            disabled: true,
          }}
          errors={errors}
          type="TEXT"
        />
        <FormInput
          propsFormControl={{ mb: 4 }}
          label="Description"
          inputProps={{
            ...form.register('desc'),
            name: 'desc',
            placeholder: 'Description',
            disabled: true,
          }}
          errors={errors}
          type="TEXTAREA"
        />
        <FormInput
          propsFormControl={{ mb: 4 }}
          label="Address"
          inputProps={{
            ...form.register('address'),
            name: 'address',
            placeholder: 'Address',
            disabled: true,
          }}
          errors={errors}
          type="TEXT"
        />
        <FormInput
          propsFormControl={{ mb: 4 }}
          label="Image"
          inputProps={{
            ...form.register('image'),
            name: 'image',
            placeholder: 'Address',
            disabled: true,
            form: form,
          }}
          errors={errors}
          type="IMAGE"
        />
        <Tabs isLazy>
          <TabList>
            <Tab>Reseller</Tab>
            <Tab>Produsen</Tab>
            <Tab>Permintaan</Tab>
            <Tab>Atur Komisi</Tab>
            <Tab>Produk Komunitas</Tab>
          </TabList>
          <TabPanels>
            <TabPanel background={'white'}>
              <CommunityStore isReseller />
            </TabPanel>
            <TabPanel background={'white'}>
              <CommunityStore isProdusen />
            </TabPanel>
            <TabPanel background={'white'}>
              <CommunityRequest />
            </TabPanel>
            <TabPanel>
              <CommissionSettings />
            </TabPanel>
            <TabPanel>
              <MyProduct community_id={id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
};

export default CommunityDetail;
