import { Box, Button, Flex, Image, Input } from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useRef, useState } from 'react';
import imageUpload from 'assets/img/form/upload-image.png';

const MultipleImageInput = (props) => {
  const fileInputReference = useRef(null);
  const [showRemove, setShowRemove] = useState(false);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    props.form.setValue(`${props.name}.${props.index}.file`, file);
    displayImagePreview(file);
  };
  const displayImagePreview = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        props.form.setValue(`${props.name}.${props.index}.preview`, reader.result);
      });
      reader.readAsDataURL(file);
    }
  };
  const handleDrop = (e, fileType) => {
    e.preventDefault();
    // Implement the logic for handling the dropped image here
  };

  const openFileExplorer = () => {
    // Trigger a click event on the hidden file input to open the file explorer
    fileInputReference.current.click();
  };

  return (
    <>
      <Box
        mt={3}
        htmlFor="image-upload"
        cursor="pointer"
        w="100%"
        minH={'150px'}
        maxH={'150px'}
        border="2px dashed #cbd5e0"
        borderRadius="md"
        padding={4}
        textAlign="center"
        _hover={{ borderColor: 'teal.500' }}
        onDrop={(e) => handleDrop(e, 'image')}
        justifyContent={'center'}
        alignItems={'center'}
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
        display={'flex'}
        onClick={props.image.preview ? undefined : openFileExplorer}
      >
        {props.image.preview ? (
          <Flex width={'100%'} alignItems={'center'} p={2} justifyContent={'center'} position={'relative'}>
            {showRemove && (
              <Flex
                top={0}
                left={0}
                right={0}
                bottom={0}
                justifyContent={'center'}
                alignItems={'center'}
                position={'absolute'}
                flexDirection={'row'}
              >
                <Button
                  onClick={() => {
                    const value = props.form.getValues(`${props.name}`);
                    const filter = value.filter((_, index) => index !== props.index);
                    props.form.setValue(`${props.name}`, filter);
                    if (props?.removeHandler) {
                      props.removeHandler(props.image.preview);
                    }
                  }}
                  size="sm"
                >
                  Remove
                </Button>
              </Flex>
            )}

            <Image p={4} maxHeight={150} objectFit={'cover'} src={props.image.preview} borderRadius="16px" />
          </Flex>
        ) : (
          <Image p={4} maxHeight={150} objectFit={'cover'} src={imageUpload} borderRadius="16px" />
        )}
      </Box>
      <Input
        {...props}
        ref={fileInputReference}
        type="file"
        p={2}
        minH={'150px'}
        maxH={'150px'}
        id="image-upload"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
      {props?.childrenComponent?.(props.index)}
    </>
  );
};
const SingleImageInput = (props) => {
  const fileInputReference = useRef(null);

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    props.form.setValue(props.name, file);
    displayImagePreview(file);
  };
  useEffect(() => {
    if (typeof props.form.getValues(props.name) === 'string') {
      setImagePreview(props.form.getValues(props.name));
    }
  }, [props.form.getValues(props.name)]);
  const handleDrop = (e, fileType) => {
    e.preventDefault();
    // Implement the logic for handling the dropped image here
  };

  const openFileExplorer = () => {
    // Trigger a click event on the hidden file input to open the file explorer
    fileInputReference.current.click();
  };

  const displayImagePreview = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Box
        htmlFor="image-upload"
        cursor="pointer"
        w="100%"
        minH={'150px'}
        maxH={'150px'}
        border="2px dashed #cbd5e0"
        borderRadius="md"
        padding={4}
        textAlign="center"
        _hover={{ borderColor: 'teal.500' }}
        onDrop={(e) => handleDrop(e, 'image')}
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        onClick={openFileExplorer}
      >
        {props.form.getValues(props.name) ? (
          <Image minH={'120px'} maxH={'120px'} src={imagePreview} borderRadius="16px" />
        ) : (
          <Image minH={'120px'} maxH={'120px'} src={imageUpload} borderRadius="16px" />
        )}
      </Box>
      <Input
        {...props}
        ref={fileInputReference}
        type="file"
        minH={'150px'}
        maxH={'150px'}
        id="image-upload"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
    </>
  );
};

SingleImageInput.propTypes = {
  getValues: PropTypes.func,
  image: PropTypes.any,
  form: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
};
MultipleImageInput.propTypes = {
  getValues: PropTypes.func,
  image: PropTypes.any,
  form: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  removeHandler: PropTypes.func,
  childrenComponent: PropTypes.func,
};

const ImageInput = (props) => {
  const { watch } = props.form;
  const image = watch(props.name);

  const addAnotherImage = () => {
    props.form.setValue(props.name, [...props.form.getValues(props.name), { file: null, preview: null }]);
  };
  return (
    <>
      {props.multiple ? (
        image.map((res, index) => <MultipleImageInput key={index} index={index} {...props} image={image[index]} />)
      ) : (
        <SingleImageInput {...props} />
      )}

      {props.multiple && (
        <Button mt={3} onClick={addAnotherImage}>
          Tambah Gambar
        </Button>
      )}
    </>
  );
};

ImageInput.propTypes = {
  getValues: PropTypes.func,
  name: PropTypes.string,
  form: PropTypes.object,
  multiple: PropTypes.bool,
};
ImageInput.defaultProps = {
  multiple: false,
};

export default ImageInput;
