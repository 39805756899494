import { isAxiosError } from 'axios';
import { toast } from 'components/toast';
import { Api } from 'lib/api';
import objectToFormData from 'lib/objectToFormData';

const initialState = {
  title: '',
  description: '',
  gender: '',
  category: '',
  productList: [],
  reseller_product: [],
  myProductListAffiliate: [],
};

const createProductSlice = (set) => ({
  ...initialState,

  createProduct: async (data) => {
    try {
      const formData = new FormData();
      objectToFormData(data, formData);

      const res = await Api.post({
        url: '/admin/product/create',
        data: formData,
        withCredentials: true,
      });

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  updateProduct: async (id, data) => {
    try {
      const formData = new FormData();
      objectToFormData(data, formData);

      const res = await Api.put({
        url: `/admin/product/update?id=${id}`,
        data: formData,
        withCredentials: true,
      });

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getProduct: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/product?' + query);
      set((s) => ({ ...s, productList: res, fetchLoading: false }));

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getProductAffiliate: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/reseller/product?' + query);
      set((s) => ({ ...s, reseller_product: res, fetchLoading: false }));

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getMyProductAffiliate: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/affiliate/list?' + query);
      set((s) => ({ ...s, myProductListAffiliate: res, fetchLoading: false }));

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getProductDetail: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/product/detail?' + query);
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  generateAffliateLink: async (data) => {
    try {
      const res = await Api.post({
        url: '/affiliate/generate',
        data: data,
        withCredentials: true,
      });

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  deleteProduct: async (id, callback) => {
    try {
      const res = await Api.delete('/admin/product/delete?id=' + id);
      callback();
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
});

export default createProductSlice;
