import { Box, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SidebarContext } from 'contexts/SidebarContext';
import { routes } from 'routes.js';

const Auth = (props) => {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/auth/full-screen-maps';
  };

  const getRoutes = (routes) => {
    return routes.map((property, key) => {
      if (property.layout === '/auth') {
        return <Route path={property.layout + property.path} component={property.component} key={key} />;
      }
      if (property.collapse) {
        return getRoutes(property.items);
      }
      return property.category ? getRoutes(property.items) : null;
    });
  };

  const authBg = useColorModeValue('white', 'navy.900');
  document.documentElement.dir = 'ltr';

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          {getRoute() ? (
            <Box mx="auto" minH="100vh">
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/auth" to="/auth/sign-in/default" />
              </Switch>
            </Box>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
};

Auth.propTypes = {
  // Add your prop type definitions here
};

export default Auth;
