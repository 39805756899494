import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Textarea,
  HStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Form from 'components/form';
import Select from 'components/searchSelect';
import { updateProductSchema } from 'lib/schema';
import useStore from 'store/useStore';
import Variants from './variants';

const ModalUpdateProduct = ({ isOpen, onOpen, onClose, data }) => {
  const form = useForm({
    resolver: zodResolver(updateProductSchema),
    defaultValues: {
      ...data,
    },
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      form.reset({ ...data });
    }
  }, [data]);

  const { updateProduct, categoryList, getCategory } = useStore();

  const mappingCategoryList = categoryList?.data?.map((result) => ({
    label: result.name,
    ...result,
  }));
  const {
    formState: { errors },
  } = form;
  const onSubmit = async (values) => {
    try {
      const castValues = {
        ...values,
        variants: values.variants.map((value) => ({
          ...value,
          price: Number(value.price),
          stocks: Number(value.stocks),
          weight: Number(value.weight),
        })),
      };

      await toast.promise(
        updateProduct(data._id, castValues),
        {
          loading: 'Loading',
          success: 'product created',
          error: 'Error when fetching',
        },
        {
          position: 'bottom-center',
        },
      );
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const initialReference = React.useRef(null);
  const finalReference = React.useRef(null);

  return (
    <>
      <Modal
        initialFocusRef={initialReference}
        finalFocusRef={finalReference}
        isOpen={isOpen}
        size="6xl"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent ml={10} mr={10} mt={10} mb={10}>
          <ModalHeader>Variasi</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box gap="20px" px={'20px'} py={'20px'} mb="20px">
              <Form methods={form} onSubmit={onSubmit}>
                <FormControl mb={4}>
                  <FormLabel>Nama Produk</FormLabel>
                  <Input type="text" name="title" {...form.register('title')} />
                  <ErrorMessage
                    errors={errors}
                    name="title"
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Deskripsi</FormLabel>
                  <Textarea
                    {...form.register('description')}
                    name="description"
                    placeholder="Deskripsi"
                    resize={'none'}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                <HStack mb={4} justifyContent={'center'} alignItems={'center'}>
                  <FormControl
                    onClick={() =>
                      getCategory({
                        page: 1,
                        limit: 10,
                      })
                    }
                  >
                    <FormLabel>Kategori</FormLabel>
                    <Select
                      name="category"
                      options={mappingCategoryList}
                      placeholder="Select category"
                      closeMenuOnSelect
                      size="md"
                      onChange={(value) => form.setValue('category', value?._id)}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="category"
                      render={({ message }) =>
                        message && (
                          <Alert status="error">
                            <AlertIcon />
                            <Box>
                              <Text fontSize="sm" color="red.500">
                                {message}
                              </Text>
                            </Box>
                          </Alert>
                        )
                      }
                    />
                  </FormControl>
                </HStack>
                <Variants form={form} />

                <Button mt={6} colorScheme="teal" type="submit">
                  Submit
                </Button>
              </Form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

ModalUpdateProduct.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default ModalUpdateProduct;
