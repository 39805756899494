export const columnsDataCheck = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'PROGRESS',
    accessor: 'progress',
  },
  {
    Header: 'QUANTITY',
    accessor: 'quantity',
  },
  {
    Header: 'DATE',
    accessor: 'date',
  },
];
export const columnsDataComplex = [
  {
    Header: 'NAMA PRODUK',
    accessor: 'product_detail.title',
  },
  {
    Header: 'TERJUAL',
    accessor: 'total_sales',
  },
  {
    Header: 'DETAIL PRODUK',
    accessor: '_id',
  },
];

export const orderData = [
  {
    header: 'PRODUCT ID',
    accessorKey: 'product._id',
    cell: (data) => data.getValue() ?? '-',
  },
  {
    header: 'NAME',
    accessorKey: 'product.title',
    cell: (data) => data.getValue() ?? '-',
  },
  // {
  //   header: 'STATUS SELLER',
  //   cell: (data) => ({
  //     status_seller: data.getValue()?.row?.status_seller,
  //     order_item_id: data.getValue()?.row?.order_items?._id,
  //   }),
  // },
  {
    header: 'DELIVERY SERVICE',
    accessorKey: 'shipping_provider',
    cell: (data) =>
      data.getValue()?.provider
        ? `${String(data.getValue()?.provider).toUpperCase()}-${String(
            data.getValue()?.provider?.service,
          ).toUpperCase()}`
        : '-',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
  },
  // {
  //   header: 'USER DETAIL',
  //   accessorKey: 'order.contact_information',
  // },
];
