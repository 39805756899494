import axios, { isAxiosError } from 'axios';
import toast from 'react-hot-toast';
import { StateCreator } from 'zustand';
import { Api } from 'lib/api';
import objectToFormData from 'lib/objectToFormData';

const initialState = {
  address: [],
};

const createAuthSlice = (set) => ({
  ...initialState,
  setConfig: async (data) => {
    try {
      const formData = new FormData();
      objectToFormData(data, formData);
      await Api.post({ url: 'admin/config/create', data: formData });
    } catch (error) {
      if (isAxiosError(error)) toast.error(error.response?.data.message);
      toast.error('Terjadi Kesalahan');
    }
  },
  getConfig: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      return await Api.get('config?' + query);
    } catch (error) {
      if (isAxiosError(error)) toast.error(error.response?.data.message);
      toast.error('Terjadi Kesalahan');
    }
  },
});

export default createAuthSlice;
