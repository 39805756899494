import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FaRupiahSign, FaShop } from 'react-icons/fa6';
import { GrTransaction } from 'react-icons/gr';
import { MdCollectionsBookmark, MdOutlineAttachMoney, MdGroup } from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import formatRupiah from 'lib/formatRupiah';
import useStore from 'store/useStore';
import CommunityDetail from './components/CommunityDetail';
import CommunityPagination from './components/CommunityPagination';
import ModalForm from './components/ModalForm';
import ModalFormJoinCommunity from './components/ModalFormJoinCommunity';

const Community = () => {
  const {
    user,
    getCommunityDashboard,
    community_dashboard: { data },
  } = useStore();
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'white');

  useEffect(() => {
    // getCommunityDashboard();
  }, []);

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      display={'flex'}
      flexDirection={'column'}
      backgroundColor={'white'}
    >
      {user.role === 1 && (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 5 }} gap="20px" mb="20px">
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Icon w="32px" h="32px" as={MdCollectionsBookmark} color={brandColor} />}
              />
            }
            name="Komunitas"
            value={data?.total_community || 0}
          />
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={FaShop} color={brandColor} />} />
            }
            name="Produk Komunitas"
            value={data?.total_product || 0}
          />
          <MiniStatistics
            startContent={
              <IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdGroup} color={brandColor} />} />
            }
            name="Anggota Komunitas"
            value={data?.total_member_community || 0}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Icon w="32px" h="32px" as={GrTransaction} color={brandColor} />}
              />
            }
            name="Total Transaksi"
            value={data?.total_transactions || 0}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Icon w="32px" h="32px" as={FaRupiahSign} color={brandColor} />}
              />
            }
            name="Nilai Transaksi"
            value={formatRupiah(data?.transactions_value || 0)}
          />
        </SimpleGrid>
      )}
      <Tabs isLazy>
        <TabList>
          {user.role === 1 && <Tab>Komunitas</Tab>}

          {user.role === 7 && <Tab>Detail Komunitas Saya</Tab>}
        </TabList>
        <TabPanels>
          {user.role === 1 && (
            <TabPanel background={'white'}>
              <Box gap="20px" mb="20px">
                <Flex direction={'row'} justify={'center'} align={'center'}>
                  <ModalForm />
                </Flex>
                <CommunityPagination />
              </Box>
            </TabPanel>
          )}
          {user.role === 7 && (
            <TabPanel background={'white'}>
              <CommunityDetail />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Community;
