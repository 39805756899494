/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useStore from 'store/useStore';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user] = useStore((s) => [s.user]);
  const isAuthenticated = () => {
    return user;
    // Implement your authentication logic here.
    // Return true if the user is authenticated, false otherwise.
    // You might want to use state, a context, or a global state management library to handle authentication.
    // Example: return someAuthenticationFunction();
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          return <Component {...props} />;
        } else {
          sessionStorage.setItem('redirectURL', props.location.pathname + props.location.search);
          return <Redirect to="/auth" />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;
