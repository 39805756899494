import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteDialog from 'components/deleteDialog';
import Table from 'components/table';
import useStore from 'store/useStore';

const CategoryDigital = () => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const pageParameter = query?.get('page');
  const limitParameter = query?.get('limit');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [categoryList, getCategoryHandler, deleteCategory] = useStore((s) => [
    s.categoryList,
    s.getCategory,
    s.deleteCategory,
  ]);

  const columns = [
    { header: 'NAMA KATEGORI', accessorKey: 'name' },
    //react table if average_weight is null, it will return 0
    {
      header: 'SUB KATEGORI',
      accessorKey: 'category_sub',
      cell: (data) => data.getValue() ?? '-',
    },
    {
      header: 'aksi',
      accessorKey: '_id',
      cell: ({ getValue }) => (
        <div className="flex gap-2">
          <Button size="xs" variant="edit" />
          <DeleteDialog
            title={`Hapus`}
            desc={`Anda Yakin Ingin Melakukan Ini?`}
            handler={(callback) => deleteCategory(getValue(), callback)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (pageParameter) {
      setPagination((res) => ({
        ...res,
        pageIndex: Number(pageParameter),
        pageSize: Number(limitParameter),
      }));
    }
  }, [JSON.stringify(pageParameter)]);

  useEffect(() => {
    toast.promise(
      getCategoryHandler({
        page: pageIndex + 1,
        limit: pageSize,
        type: 'digital',
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Data Category',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);

  return (
    <Table
      pagination={{
        pageIndex,
        pageSize,
        totalPages: categoryList?.total_pages,
      }}
      setPagination={setPagination}
      data={categoryList?.data}
      columns={columns}
    />
  );
};

export default CategoryDigital;
// PropTypes
CategoryDigital.propTypes = {};
