import { z } from 'zod';

export const loginSchema = z.object({
  email: z.string(),
  password: z.string(),
});

export const productSchema = z.object({
  title: z.string().min(1, {
    message: 'Judul diperlukan', // Title is required
  }),
  description: z.string().min(1, {
    message: 'Deskripsi diperlukan', // Description is required
  }),
  category: z.string().min(1, {
    message: 'Kategori diperlukan', // Category is required
  }),
  community: z.string().optional(),
  affiliate_commission: z.object({
    type: z.string(),
    value: z.number(),
  }),
  variants: z.array(
    z.object({
      title: z.string().min(1, {
        message: 'Judul varian diperlukan', // Variant title is required
      }),
      gender: z.string().optional(),
      price: z
        .number()
        .or(
          z
            .string()
            .min(1, {
              message: 'Harga diperlukan', // Price is required
            })
            .regex(/\d+/)
            .transform(Number),
        )
        .refine((n) => n > 0, {
          message: 'Harga harus angka positif', // Price must be a positive number
        }),
      size: z.string().min(1, {
        message: 'Ukuran diperlukan', // Size is required
      }),
      stocks: z
        .number()
        .or(
          z
            .string()
            .min(1, {
              message: 'Stok diperlukan', // Stocks is required
            })
            .regex(/\d+/)
            .transform(Number),
        )
        .refine((n) => n > 0, {
          message: 'Stok harus angka positif', // Stocks must be a positive number
        }),
      weight: z
        .number()
        .or(
          z
            .string()
            .min(1, {
              message: 'Berat diperlukan', // Weight is required
            })
            .regex(/\d+/)
            .transform(Number),
        )
        .refine((n) => n > 0, {
          message: 'Berat harus angka positif', // Weight must be a positive number
        }),
      unit_mass: z.string().min(1, {
        message: 'Satuan Berat Di Perlukan', // Variant description is required
      }),
      description: z.string().min(1, {
        message: 'Deskripsi varian diperlukan', // Variant description is required
      }),
      color: z.string().optional(),
      files: z.instanceof(File),
    }),
  ),
});

export const updateProductSchema = z.object({
  title: z.string().min(1, {
    message: 'Judul diperlukan', // Title is required
  }),
  description: z.string().min(1, {
    message: 'Deskripsi diperlukan', // Description is required
  }),
  category: z.string().min(1, {
    message: 'Kategori diperlukan', // Category is required
  }),
  variants: z.array(
    z.object({
      _id: z.string().min(1, {
        message: 'ID varian diperlukan', // Variant ID is required
      }),
      title: z.string().min(1, {
        message: 'Judul varian diperlukan', // Variant title is required
      }),
      gender: z.string().optional(),
      price: z
        .number()
        .or(
          z
            .string()
            .min(1, {
              message: 'Harga diperlukan', // Price is required
            })
            .regex(/\d+/)
            .transform(Number),
        )
        .refine((n) => n > 0, {
          message: 'Harga harus angka positif', // Price must be a positive number
        }),
      size: z.string().min(1, {
        message: 'Ukuran diperlukan', // Size is required
      }),
      stocks: z
        .number()
        .or(
          z
            .string()
            .min(1, {
              message: 'Stok diperlukan', // Stocks is required
            })
            .regex(/\d+/)
            .transform(Number),
        )
        .refine((n) => n > 0, {
          message: 'Stok harus angka positif', // Stocks must be a positive number
        }),
      weight: z
        .number()
        .or(
          z
            .string()
            .min(1, {
              message: 'Berat diperlukan', // Weight is required
            })
            .regex(/\d+/)
            .transform(Number),
        )
        .refine((n) => n > 0, {
          message: 'Berat harus angka positif', // Weight must be a positive number
        }),
      description: z.string().min(1, {
        message: 'Deskripsi varian diperlukan', // Variant description is required
      }),
      color: z.string().optional(),
      files: z.instanceof(File).optional(),
    }),
  ),
});
const fileSchema = z.instanceof(File).or(z.undefined());

const bannerSchema = z
  .object({
    preview: z.string().optional(),
    file: fileSchema,
  })
  .refine((data) => data.file instanceof File, {
    message: 'Banner untuk komunitas di perlukan', // Custom error message
  });

export const categorySchema = z.object({
  name: z.string().min(1, {
    message: 'Nama kategori diperlukan', // NAMA KATEGORI required
  }),
  sub_category: z.string().optional(),
  is_parent: z.boolean(),
  type: z.string().min(1, {
    message: 'Tipe kategori diperlukan', // Category type required
  }),
});
export const communitySchema = z.object({
  name: z.string().min(1, {
    message: 'Nama Komunitas Dibutuhkan', // Community name required
  }),
  desc: z.string().min(1, {
    message: 'Tipe Komunitas diperlukan', // Community type required
  }),
  email_admin: z.string().min(1, {
    message: 'Email Admin Komunitas diperlukan', // Community Email Admin required
  }),
  phone_number_admin: z.string().min(1, {
    message: 'Nomor Telepon Admin Komunitas diperlukan', // Community Phone Number Admin required
  }),
  name_admin: z.string().min(1, {
    message: 'Nama Admin Komunitas diperlukan', // Community Name Admin required
  }),
  address: z.string().min(1, {
    message: 'Alamat Komunitas diperlukan', // Community Address required
  }),
  notif_member: z.string().min(1, {
    message: 'Notif Member Komunitas diperlukan', // Community Address required
  }),
  video: z.string().min(1, {
    message: 'Video Komunitas diperlukan', // Community Address required
  }),
  file: z.any().refine((object) => object instanceof File, {
    // Custom error message when file is not an instance of File
    message: 'Gambar komunitas di butuhkan',
  }),
  banner: z.array(bannerSchema),
});

export const updateCommunitySchema = z.object({
  name: z.string().min(1, {
    message: 'Nama Komunitas diperlukan', // Community name required
  }),
  desc: z.string().min(1, {
    message: 'Tipe Komunitas diperlukan', // Community type required
  }),
  address: z.string().min(1, {
    message: 'Alamat Komunitas diperlukan', // Community Address required
  }),
  notif_member: z.string().min(1, {
    message: 'Notif Member Komunitas diperlukan', // Community Address required
  }),
  file: z.union([z.instanceof(File).optional().nullable(), z.string().optional().nullable()]),
  video: z.string().min(1, {
    message: 'Video Komunitas diperlukan', // Community Address required
  }),
  banner: z.array(
    z.object({
      preview: z.string().optional().nullable(),
      file: z.instanceof(File).optional().nullable(),
    }),
  ),
});
export const joinCommunitySchema = z.object({
  referral_code: z.string().min(1, {
    message: 'Kode Referral Komunitas diperlukan', // Community Referral Code required
  }),
});

export const joinCommunityWithoutUserSchema = z.object({
  referral_code: z.string().min(1, {
    message: 'Kode Referral Komunitas diperlukan', // Community Referral Code required
  }),
  email: z.string().min(1, {
    message: 'Email Di perlukan', // Community Referral Code required
  }),
});
export const sharingProfitSchema = z.object({
  data: z.array(
    z.object({
      name: z.string().min(1, {
        message: 'Nama Komisi diperlukan', // Variant title is required
      }),
      commission: z.object({
        value: z
          .number()
          .or(
            z
              .string()
              .min(1, {
                message: 'Nilai diperlukan', // Stocks is required
              })
              .regex(/\d+/)
              .transform(Number),
          )
          .refine((n) => n > 0, {
            message: 'Nilai harus angka positif', // Stocks must be a positive number
          }),
        min_product_pcs: z
          .number()
          .or(
            z
              .string()
              .min(1, {
                message: 'Minimal Produk diperlukan', // Stocks is required
              })
              .regex(/\d+/)
              .transform(Number),
          )
          .refine((n) => n > 0, {
            message: 'Minimal Produk harus angka positif', // Stocks must be a positive number
          }),
        type: z.string(),
      }),
    }),
  ),
});

export const resellerSchema = z.object({
  dropship_commission: z.object({
    type: z.string(),
    value: z.number(),
  }),
});

export const updateSharingProfitSchema = z.object({
  member_sharing_profit: z.object({
    value: z.number(),
    type: z.string(),
  }),
  store_id: z.string().min(1, {
    message: 'Toko diperlukan', // Store is required
  }),
  my_sharing_profit: z.object({
    value: z.number(),
    type: z.string(),
  }),
});

export const withdrawSchema = z.object({
  total_amount: z.number().min(1, {
    message: 'Jumlah diperlukan', // Amount is required
  }),
});
