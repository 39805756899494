import { Box, Button, FormControl, FormLabel, Alert, AlertIcon, Text, Textarea } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Form from 'components/form';
import useStore from 'store/useStore';

const FormPrivacyAndPolicy = () => {
  const { getConfig, setConfig } = useStore();
  const form = useForm({
    defaultValues: {
      _id: '',
      config_name: 'privacy_policy',
      value: '',
    },
  });
  const {
    formState: { errors, isSubmitting },
  } = form;

  const launch = async () => {
    const res = await getConfig({ config_name: 'privacy_policy' });
    if (res?.data) {
      form.reset(res.data);
    }
  };

  useEffect(() => {
    launch();
  }, []);

  const onSubmit = async (values) => {
    delete values._id;
    const castValues = {
      ...values,
    };
    toast.promise(
      setConfig(castValues),
      {
        loading: 'Memuat...',
        success: 'Komisi Berhasil Di Ubah',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  };

  return (
    <Form methods={form} onSubmit={onSubmit}>
      <FormControl mb={4}>
        <FormLabel>Deskripsi</FormLabel>
        <Textarea {...form.register('value')} name="value" placeholder="Description" resize={'none'} />
        <ErrorMessage
          errors={errors}
          name="description"
          render={({ message }) =>
            message && (
              <Alert status="error">
                <AlertIcon />
                <Box>
                  <Text fontSize="sm" color="red.500">
                    {message}
                  </Text>
                </Box>
              </Alert>
            )
          }
        />
      </FormControl>
      <Button isLoading={isSubmitting} mt={10} colorScheme="blue" type="submit">
        Simpan
      </Button>
    </Form>
  );
};

export default FormPrivacyAndPolicy;
