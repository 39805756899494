/* eslint-disable react/prop-types */
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Input,
  FormControl,
  FormLabel,
  AlertIcon,
  Alert,
  Text,
  Textarea,
  Flex,
  Image,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import imageUpload from 'assets/img/form/upload-image.png';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import { updateCommunitySchema } from 'lib/schema';
import useStore from 'store/useStore';

const ModalUpdate = ({ isOpen, onOpen, onClose, communityData }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [showRemove, setShowRemove] = useState(false);
  const form = useForm({
    resolver: zodResolver(updateCommunitySchema),
    defaultValues: {
      name: '',
      desc: '',
      video: '',
      notif_member: '',
      file: null,
      banner: [
        {
          file: null,
          preview: null,
        },
      ],
    },
  });
  useEffect(() => {
    form.reset({
      ...communityData,
      banner: communityData?.banner
        ? communityData?.banner?.map((res) => ({
            file: null,
            preview: res,
          }))
        : [
            {
              file: null,
              preview: null,
            },
          ],
    });
    if (communityData?.image) {
      form.setValue('file', communityData.image);
      setImagePreview(communityData.image);
    }
  }, [JSON.stringify(communityData)]);

  const {
    formState: { errors },
  } = form;
  const { updateCommunity, createLoading, getCommunity, removeBannerCommunity } = useStore();
  const fileInputReference = useRef(null);

  useEffect(
    () => () => {
      if (!isOpen) {
        form.reset({
          name: '',
          desc: '',
          video: '',
          notif_member: '',
          file: null,
          banner: [
            {
              file: null,
              preview: null,
            },
          ],
        });
      }
    },
    [isOpen],
  );
  const displayImagePreview = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    form.setValue(`file`, file);
    displayImagePreview(file);
  };

  const onSubmitFinished = () => {
    onClose();
    getCommunity();
  };

  const onSubmit = async (values) => {
    if (typeof values.file === 'string') {
      delete values.file;
    }
    const castValues = {
      ...values,
      banner: values.banner.filter((res) => res.file !== null && res.file !== undefined).map((res) => res.file),
    };
    await updateCommunity(communityData._id, castValues, onSubmitFinished);
  };
  const handleDrop = (e, fileType) => {
    e.preventDefault();
    // Implement the logic for handling the dropped image here
  };

  const openFileExplorer = () => {
    // Trigger a click event on the hidden file input to open the file explorer
    fileInputReference.current.click();
  };
  const removeBanner = (imageString) => {
    removeBannerCommunity(communityData._id, { image_url: imageString });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} px={10} py={4}>
      <Modal scrollBehavior="outside" onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ubah Komunitas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form methods={form} onSubmit={onSubmit}>
              <FormControl mb={4} onDragOver={(e) => e.preventDefault()}>
                <FormLabel>Upload Image</FormLabel>
                <Box
                  htmlFor="image-upload"
                  cursor="pointer"
                  w="100%"
                  border="2px dashed #cbd5e0"
                  borderRadius="md"
                  padding={4}
                  textAlign="center"
                  _hover={{ borderColor: 'teal.500' }}
                  onDrop={(e) => handleDrop(e, 'image')}
                  justifyContent={'center'}
                  alignItems={'center'}
                  display={'flex'}
                  onMouseEnter={() => setShowRemove(true)}
                  onMouseLeave={() => setShowRemove(false)}
                  onClick={() => !imagePreview && openFileExplorer()}
                >
                  {form.getValues(`file`) ? (
                    <Flex width={'100%'} alignItems={'center'} justifyContent={'center'} position={'relative'}>
                      {showRemove && (
                        <Flex
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          justifyContent={'center'}
                          alignItems={'center'}
                          position={'absolute'}
                          flexDirection={'row'}
                        >
                          <Button
                            onClick={() => {
                              form.resetField(`file`);
                              setImagePreview(null);
                            }}
                            size="sm"
                          >
                            Remove
                          </Button>
                        </Flex>
                      )}

                      <Image maxHeight={150} objectFit={'cover'} src={imagePreview} borderRadius="16px" />
                    </Flex>
                  ) : (
                    <Image maxHeight={150} objectFit={'cover'} src={imageUpload} borderRadius="16px" />
                  )}
                </Box>
                <Input
                  {...form.register(`file`)}
                  ref={fileInputReference}
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />
                <ErrorMessage
                  errors={errors}
                  name={`file`}
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormInput
                propsFormControl={{ mb: 4 }}
                label="Image"
                inputProps={{
                  ...form.register('banner'),
                  name: 'banner',
                  placeholder: 'Banner',
                  form: form,
                  multiple: true,
                  removeHandler: removeBanner,
                }}
                errors={errors}
                type="IMAGE"
              />
              <FormControl mb={4}>
                <FormLabel>Community Name</FormLabel>
                <Input type="text" name="name" placeholder="Community name" {...form.register('name')} />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Notif Member</FormLabel>
                <Input type="text" name="name" placeholder="Notif Member" {...form.register('notif_member')} />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Description</FormLabel>
                <Textarea {...form.register('desc')} name="desc" placeholder="Description" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Video Link</FormLabel>
                <Input {...form.register('video')} name="video" placeholder="Video" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="video"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Address</FormLabel>
                <Textarea {...form.register('address')} name="address" placeholder="Address" resize={'none'} />
                <ErrorMessage
                  errors={errors}
                  name="address"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <Button isLoading={createLoading} mt={10} colorScheme="teal" type="submit">
                Save
              </Button>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalUpdate;
