import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import useStore from 'store/useStore';
import ColumnsTable from './components/table';

const OrderPage = () => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <ColumnsTable />;
    </Box>
  );
};

export default OrderPage;
