/* eslint-disable unicorn/numeric-separators-style */
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  Input,
  FormControl,
  FormLabel,
  AlertIcon,
  Alert,
  Text,
  useColorModeValue,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import 'assets/css/App.css';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaRupiahSign } from 'react-icons/fa6';
import { MdAttachMoney } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import MiniStatistics from 'components/card/MiniStatistics';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import IconBox from 'components/icons/IconBox';
import formatRupiah from 'lib/formatRupiah';
import { withdrawSchema } from 'lib/schema';
import unformatRupiah from 'lib/unformatRupiah';
import useStore from 'store/useStore';

const ModalWithDraw = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const form = useForm({
    resolver: zodResolver(withdrawSchema),
    defaultValues: {
      total_amount: '0',
    },
  });
  const {
    formState: { errors },
  } = form;
  const { wallet, getWallet, withdraw } = useStore();
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  useEffect(
    () => () => {
      if (!isOpen) {
        form.reset({
          total_amount: '0',
        });
      }
    },
    [isOpen],
  );
  useEffect(() => {
    getWallet();
  }, []);

  const onSubmit = async (values) => {
    if (values.total_amount > wallet.balance) {
      form.setError('total_amount', {
        message: 'Jumlah Uang Tidak Cukup',
        type: 'maxLength',
      });
    } else {
      await withdraw(values);
    }
    onClose();
  };

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} py={4}>
      <MiniStatistics
        startContent={
          <IconBox
            w="56px"
            h="56px"
            bg={boxBg}
            icon={<Icon w="32px" h="32px" as={FaRupiahSign} color={brandColor} />}
          />
        }
        name="Dompet"
        value={formatRupiah(wallet?.balance || 0)}
      />
      <Button ml={'20px'} colorScheme="blue" onClick={onOpen}>
        Tarik Saldo
      </Button>
      <Modal blockScrollOnMount={false} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tarik Saldo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box px={'10px'} py={'10px'} mb="10px">
              <Text fontWeight={'bold'} fontSize={12} color={'red.300'}>
                Saldo yang di tarik akan di trasnfer ke akun ipaymu anda
              </Text>
              <Flex height={4} />
              <Form methods={form} onSubmit={onSubmit}>
                <FormControl mb={4}>
                  <FormLabel>Jumlah Uang</FormLabel>
                  <NumericFormat
                    {...form.register('total_amount')}
                    placeholder={formatRupiah(10000)}
                    className="input-custom"
                    valueIsNumericString={true}
                    prefix="Rp. "
                    onValueChange={({ floatValue }) => form.setValue('total_amount', floatValue)}
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                  />
                  <ErrorMessage
                    errors={errors}
                    name="total_amount"
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                {/* <FormInput
                  label="Foto Kartu Identitas"
                  propsFormControl={{ mb: 4 }}
                  inputProps={{
                    ...form.register('file'),
                    name: 'file',
                    form: form,
                    multiple: false,
                  }}
                  errors={errors}
                  type="IMAGE"
                /> */}
                <Button mt={5} colorScheme="teal" type="submit">
                  Simpan
                </Button>
              </Form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalWithDraw;
