import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = ({ ref, chartOptions, chartData }) => {
  return <ReactApexChart ref={ref} options={chartOptions} series={chartData} type="line" width="100%" height="100%" />;
};

export default LineChart;

LineChart.PropTypes = {
  ref: PropTypes.func,
  chartOptions: PropTypes.array,
  chartData: PropTypes.array,
};
