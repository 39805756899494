import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Flex,
  Text,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useState } from 'react';

const ModalVariantDetail = ({ isOpen, onClose, data }) => {
  const [imageSelected, setImageSelected] = useState(0);

  const initialReference = React.useRef(null);
  const finalReference = React.useRef(null);

  return (
    <>
      <Modal
        initialFocusRef={initialReference}
        finalFocusRef={finalReference}
        isOpen={isOpen}
        size="6xl"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent ml={10} mr={10} mt={10} mb={10}>
          <ModalHeader>Variasi</ModalHeader>
          <ModalCloseButton />
          {Object.values(data).length > 0 && (
            <ModalBody>
              <Flex>
                <Flex width={'50%'} align={'center'}>
                  <Flex width={'100%'} align={'center'}>
                    <Flex flexDirection={'column'}>
                      {data?.images?.map((res, index) => {
                        return (
                          <Image
                            key={index}
                            onClick={() => setImageSelected(index)}
                            src={res}
                            width={'50px'}
                            borderRadius="16px"
                            mb="28px"
                          />
                        );
                      })}
                    </Flex>
                    <Flex justify={'center'} width={'100%'}>
                      <Image src={data?.images[imageSelected]} width={'150px'} borderRadius="16px" mb="28px" />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex width={'50%'} flexDirection={'column'}>
                  <Text fontWeight={'bold'} color={'gray.300'}>
                    {data?.category?.name}
                  </Text>
                  <Text fontWeight={'bold'} fontSize={'3xl'}>
                    {data?.title}
                  </Text>
                  <Text fontWeight={'bold'} fontSize={'1xl'}>
                    Rp, {data?.price}
                  </Text>
                  <Text mb={10} fontWeight={'medium'} color={'gray.500'} mt={10} fontSize={'1xl'}>
                    {data?.description}
                  </Text>
                </Flex>
              </Flex>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

ModalVariantDetail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
  }),
  category: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default ModalVariantDetail;
