import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import useStore from 'store/useStore';

const FormCommission = () => {
  const { getConfig, setConfig } = useStore();
  const form = useForm({
    defaultValues: {
      _id: '',
      config_name: 'commission',
      value: 0,
    },
  });
  const {
    formState: { errors, isSubmitting },
  } = form;

  const launch = async () => {
    const res = await getConfig({ config_name: 'commission' });
    if (res?.data) {
      form.reset(res.data);
    }
  };

  useEffect(() => {
    launch();
  }, []);

  const onSubmit = async (values) => {
    delete values._id;
    const castValues = {
      ...values,
      value: Number(values.value),
    };
    toast.promise(
      setConfig(castValues),
      {
        loading: 'Memuat...',
        success: 'Komisi Berhasil Di Ubah',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  };

  return (
    <Form methods={form} onSubmit={onSubmit}>
      <FormInput
        propsFormControl={{ mb: 4 }}
        label="Komisi"
        inputProps={{
          ...form.register('value'),
          name: 'value',
          placeholder: '10%',
          type: 'number',
          disabled: false,
        }}
        errors={errors}
        type="TEXT"
      />
      <Button isLoading={isSubmitting} mt={4} colorScheme="blue" type="submit">
        Simpan
      </Button>
    </Form>
  );
};

export default FormCommission;
