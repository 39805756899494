import { create } from 'zustand';
import createAddress from './slices/addressSlice';
import createCategorySlice from './slices/categorySlices';
import createCommunitySlice from './slices/communitySlices';
import createConfigSlice from './slices/configSlice';
import createDashboardSlice from './slices/dashboardSlices';
import createOrderSlice from './slices/orderSlices';
import createProductSlice from './slices/productSlices';
import createResellerSlice from './slices/resellerSlice';
import createSharingProfitSlice from './slices/sharingProfitSlices';
import createStoreSlice from './slices/storeSlice';
import createAuthSlice from './slices/userSlices';

const useStore = create()((...a) => ({
  ...createAuthSlice(...a),
  ...createProductSlice(...a),
  ...createCategorySlice(...a),
  ...createDashboardSlice(...a),
  ...createOrderSlice(...a),
  ...createCommunitySlice(...a),
  ...createSharingProfitSlice(...a),
  ...createAddress(...a),
  ...createStoreSlice(...a),
  ...createResellerSlice(...a),
  ...createConfigSlice(...a),
}));

export default useStore;
