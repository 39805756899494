import axios, { isAxiosError } from 'axios';
import { StateCreator } from 'zustand';
import { toast } from 'components/toast';
import { Api } from 'lib/api';
import objectToFormData from 'lib/objectToFormData';

const initialState = {
  income: {
    total_income: 0,
    total_transaction: 0,
  },
  top_selling_product: {},
  transaction_by_location: { data: [] },
};

const createProductSlice = (set) => ({
  ...initialState,
  getIncome: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/dashboard/income' + query);
      set((s) => ({ ...s, income: res }));

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getTransactionGroupByLocation: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/dashboard/transaction-group-by-location' + query);
      set((s) => ({ ...s, transaction_by_location: res }));

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  getTopFiveSellingProduct: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/dashboard/top-selling-products' + query);
      set((s) => ({ ...s, top_selling_product: res }));

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
});

export default createProductSlice;
