import { Box, Input, Button, FormControl, FormLabel, Alert, AlertIcon, Text, Flex, Image } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { MdOutlineAddBox, MdDeleteForever } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import imageUpload from 'assets/img/form/upload-image.png';
import FormInput from 'components/form/Input';
import formatRupiah from 'lib/formatRupiah';

const VariantsForm = ({ getValues, setValue, removeCurrrentVariant, register, index, errors, append }) => {
  const fileInputReference = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showRemove, setShowRemove] = useState(false);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setValue(`variants.${index}.files`, file);
    displayImagePreview(file);
  };
  const handleDrop = (e, fileType) => {
    e.preventDefault();
    // Implement the logic for handling the dropped image here
  };

  useEffect(() => {
    const unit_mass = getValues('unit_mass');
    if (!unit_mass) {
      setValue(`variants.${index}.unit_mass`, 'kg');
    }
  }, []);

  const openFileExplorer = () => {
    // Trigger a click event on the hidden file input to open the file explorer
    fileInputReference.current.click();
  };

  const displayImagePreview = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImagePreview(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };
  return (
    <Flex flexDirection={'row'}>
      <Flex
        w={'50%'}
        borderRadius={'2xl'}
        px={5}
        py={5}
        height={'min-content'}
        flexDirection={'column'}
        backgroundColor={'white'}
      >
        <FormInput
          propsFormControl={{ mb: 4 }}
          label="Deskripsi"
          inputProps={{
            ...register(`variants.${index}.description`),
            name: `variants.${index}.description`,
            placeholder: 'Deskripsi',
          }}
          errors={errors}
          type="TEXTAREA"
        />
        <FormControl mb={4} onDragOver={(e) => e.preventDefault()}>
          <FormLabel>Unggah Gambar</FormLabel>
          <Box
            htmlFor="image-upload"
            cursor="pointer"
            w="100%"
            border="2px dashed #cbd5e0"
            borderRadius="md"
            padding={4}
            textAlign="center"
            _hover={{ borderColor: 'teal.500' }}
            onDrop={(e) => handleDrop(e, 'image')}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            onMouseEnter={() => setShowRemove(true)}
            onMouseLeave={() => setShowRemove(false)}
            onClick={openFileExplorer}
          >
            {getValues(`variants.${index}.files`) ? (
              <Flex width={'100%'} alignItems={'center'} justifyContent={'center'} position={'relative'}>
                {showRemove && (
                  <Flex
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    justifyContent={'center'}
                    alignItems={'center'}
                    position={'absolute'}
                    flexDirection={'row'}
                  >
                    <Button size="sm">Replace</Button>
                    <Flex width={10} />

                    <Button size="sm">Remove</Button>
                  </Flex>
                )}

                <Image maxHeight={150} objectFit={'cover'} src={imagePreview} borderRadius="16px" />
              </Flex>
            ) : (
              <Image maxHeight={150} objectFit={'cover'} src={imageUpload} borderRadius="16px" />
            )}
          </Box>
          <Input
            {...register(`variants.${index}.files`)}
            ref={fileInputReference}
            type="file"
            id="image-upload"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <ErrorMessage
            errors={errors}
            name={`variants.${index}.files`}
            render={({ message }) =>
              message && (
                <Alert status="error">
                  <AlertIcon />
                  <Box>
                    <Text fontSize="sm" color="red.500">
                      {message}
                    </Text>
                  </Box>
                </Alert>
              )
            }
          />
          {/* <Button mt={4}>Tambah Gambar</Button> */}
        </FormControl>
      </Flex>
      <Flex width={30} />
      <Flex
        borderRadius={'2xl'}
        px={5}
        py={5}
        height={'min-content'}
        flexDirection={'column'}
        backgroundColor={'white'}
        w={'50%'}
      >
        <FormInput
          propsFormControl={{ mb: 4, mt: 2 }}
          label="Nama Variasi / Tipe / Rasa"
          inputProps={{
            ...register(`variants.${index}.title`),
            name: `variants.${index}.title`,
            placeholder: 'Nama Variasi / Tipe / Rasa',
          }}
          errors={errors}
          type="TEXT"
        />
        <FormInput
          propsFormControl={{ mb: 4 }}
          label="Jenis Kelamin"
          inputProps={{
            ...register(`variants.${index}.gender`),
            name: `variants.${index}.gender`,
            placeholder: 'Jenis Kelamin',
            optionsData: [
              {
                label: 'Pria',
                value: 'male',
              },
              {
                label: 'Wanita',
                value: 'male',
              },
              {
                label: 'Both',
                value: 'male',
              },
            ],
            onChange: ({ value }) => setValue(`variants.${index}.gender`, value),
          }}
          errors={errors}
          type="SELECT"
        />
        <FormInput
          propsFormControl={{ mb: 4 }}
          label="Warna"
          inputProps={{
            ...register(`variants.${index}.color`),
            name: `variants.${index}.color`,
            placeholder: 'Warna',
          }}
          errors={errors}
          type="TEXT"
        />

        <Flex justify={'center'} alignItems={'end'}>
          <FormInput
            propsFormControl={{ mb: 4, mt: 2 }}
            label="Berat Variasi"
            inputProps={{
              ...register(`variants.${index}.weight`),
              name: `variants.${index}.weight`,
              placeholder: 'Berat Variasi',
            }}
            errors={errors}
            type="TEXT"
          />
          <Flex width={10} />
          <FormInput
            propsFormControl={{ mb: 4, mt: 2 }}
            inputProps={{
              ...register(`variants.${index}.unit_mass`),
              name: `variants.${index}.unit_mass`,
              optionsData: [
                {
                  label: 'Kg',
                  value: 'kg',
                },
                {
                  label: 'Gram',
                  value: 'gram',
                },
              ],
              placeholder: 'Kg',
              onChange: ({ value }) => {
                setValue(`variants.${index}.unit_mass`, value);
              },
            }}
            errors={errors}
            type="SELECT"
          />
          <Flex width={10} />
          <FormInput
            propsFormControl={{ mb: 4, mt: 2 }}
            label="Variasi Ukuran"
            inputProps={{
              ...register(`variants.${index}.size`),
              name: `variants.${index}.size`,
              placeholder: 'Variasi Ukuran',
            }}
            errors={errors}
            type="TEXT"
          />
        </Flex>
        <Flex>
          <FormInput
            propsFormControl={{ mb: 4, mt: 2 }}
            label="Stok Variasi"
            inputProps={{
              ...register(`variants.${index}.stocks`),
              name: `variants.${index}.stocks`,
              placeholder: 'Stok Variasi',
            }}
            errors={errors}
            type="TEXT"
          />
          <Flex width={10} />
          <FormControl mb={4}>
            <FormLabel>Harga Variasi</FormLabel>
            <NumericFormat
              {...register(`variants.${index}.price`)}
              // eslint-disable-next-line unicorn/numeric-separators-style
              placeholder={formatRupiah(10000)}
              style={{ marginTop: 9 }}
              className="input-custom"
              valueIsNumericString={true}
              prefix="Rp. "
              onValueChange={({ floatValue }) => {
                setValue(`variants.${index}.price`, floatValue);
              }}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
            />
          </FormControl>
          {/* <FormInput
            propsFormControl={{ mb: 4, mt: 2 }}
            label="Harga Variasi"
            inputProps={{
              ...register(`variants.${index}.price`),
              name: `variants.${index}.price`,
              placeholder: 'Harga Variasi"',
            }}
            errors={errors}
            type="TEXT"
          /> */}
        </Flex>
        <Button colorScheme="red" onClick={() => removeCurrrentVariant(index)} mt={4} width={'full'}>
          <MdDeleteForever /> Hapus Variasi
        </Button>
        <Button
          onClick={() =>
            append({
              title: '',
              price: 0,
              stocks: 0,
              weight: 0,
              description: '',
            })
          }
          colorScheme="green"
          mt={4}
          width={'full'}
        >
          <MdOutlineAddBox
            style={{
              marginRight: 10,
            }}
          />
          Tambah Variasi
        </Button>
      </Flex>
    </Flex>
  );
};

VariantsForm.propTypes = {
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  removeCurrrentVariant: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  append: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired, // Modify the object type according to your needs
};

const Variants = ({ form }) => {
  const {
    formState: { errors },
    control,
  } = form;
  const { fields, append, remove } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'variants', // unique name for your Field Array
  });
  const { register, setValue, getValues } = form;

  const removeCurrrentVariant = (index) => {
    remove(index);
  };

  return (
    <Flex mt={4} flexDirection={'column'}>
      <Text fontSize="2xl" fontWeight="bold">
        Variasi
      </Text>
      {fields.map((field, index) => {
        return (
          <VariantsForm
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            key={field._id}
            index={index}
            register={register}
            append={append}
            removeCurrrentVariant={removeCurrrentVariant}
          />
        );
      })}
    </Flex>
  );
};

Variants.propTypes = {
  form: PropTypes.object.isRequired, // Modify the object type according to your needs
};

export default Variants;
