/* eslint-disable no-nested-ternary */
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineCopy } from 'react-icons/ai';
import Card from 'components/card/Card';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import Table from 'components/table';
import toCsv from 'lib/toCsv';
import useStore from 'store/useStore';
import ModalVariantDetail from './ModalVariantDetail';

const ColumnsTable = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDataVariant, onOpen: onOpenDataVariant, onClose: onCloseDataVariant } = useDisclosure();
  const { isOpen: isOpenStatusSeller, onOpen: onOpenStatusSeller, onClose: onCloseStatusSeller } = useDisclosure();
  const [dataVariant, setDataVariant] = useState({});

  const {
    updateResi: updateResiHandler,
    ordered_product: orderProduct,
    getOrderedProduct,
    getProductDetail,
    user,
  } = useStore();

  const SELLER_STATUS = ['Mempersiapkan Barang', 'Barang Sedang Dikirim', 'Barang Di Terima'];

  const data = useMemo(() => orderProduct || [], [orderProduct.page]);
  const formResi = useForm({
    defaultValues: {
      no_resi: '',
      transaction_id: '',
      status_seller: '',
    },
  });

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(async () => {
    toast.promise(
      getOrderedProduct({
        page: pageIndex + 1,
        limit: pageSize,
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Pesanan Produk',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);

  // console.log('render', orderProduct);

  const changeStatusSellerHandler = (e, data) => {
    const {
      target: { value },
    } = e;

    if (value === SELLER_STATUS[1]) {
      formResi.setValue('status_seller', value);
      formResi.setValue('transaction_id', data.transaction_id);
      onOpenStatusSeller();
    } else {
      try {
        toast.promise(
          updateResiHandler({ status_seller: value, transaction_id: data.transaction_id }),
          {
            loading: 'Loading',
            success: 'Update resi success',
            error: 'Error update resi',
          },
          {
            position: 'bottom-center',
          },
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const form = useForm({
    defaultValues: {
      address: {
        street: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
      },
      label: '',
      _id: '',
      email: '',
      name: '',
      phone_number: '',
    },
  });

  const onSubmitUpdateResi = async (values) => {
    try {
      await toast.promise(
        updateResiHandler(values),
        {
          loading: 'Loading',
          success: 'Update resi success',
          error: 'Error update resi',
        },
        {
          position: 'bottom-center',
        },
      );
      onCloseStatusSeller();
      // history.go(0);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      header: 'ID PRODUK',
      accessorKey: 'reseller_product',
      cell: (data) => (
        <Button
          onClick={() => {
            window.open(`https://dapatkomisi.com/product/${data.getValue()}`);
          }}
        >
          {data.getValue()}
        </Button>
      ),
    },
    {
      header: 'VARIASI',
      accessorKey: 'variant',
      cell: (data) => (
        <Button
          onClick={async () => {
            const response = await getProductDetail({ id: data.row.original?.reseller_product });
            const variant = response.product.variants.filter((res) => res._id === data.row.original?.variant);
            setDataVariant(variant?.[0]);
            onOpenDataVariant();
          }}
        >
          {data.getValue()}
        </Button>
      ),
    },
    {
      header: 'STATUS PENJUAL',
      cell: (data) => {
        return (
          <Select
            value={data.row.original.status_seller}
            disabled={user?.role !== 2}
            onChange={(e) =>
              changeStatusSellerHandler(e, {
                status_seller: data.row.original.status_seller,
                transaction_id: data.row.original?._id,
              })
            }
            placeholder="Select Status"
          >
            {SELLER_STATUS.map((res, index) => (
              <option key={index} value={res}>
                {res}
              </option>
            ))}
          </Select>
        );
      },
    },
    {
      header: 'LAYANAN PENGIRIMAN',
      accessorKey: 'shipping_provider',
      cell: (data) =>
        data.getValue()?.provider
          ? `${String(data.getValue()?.provider).toUpperCase()}-${String(data.getValue()?.service).toUpperCase()}`
          : '-',
    },
    {
      header: 'Kuantitas',
      accessorKey: 'quantity',
      cell: (data) => data.getValue() ?? '-',
    },
    {
      header: 'Detail Pembeli',
      accessorKey: 'order.contact_information',
      cell: (data) => {
        return (
          <>
            <Button
              onClick={() => {
                const csv = toCsv(data.getValue());
                const blob = new Blob([csv], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'user_detail' + '.csv';
                document.body.append(link);
                link.click();
                link.remove();
              }}
              colorScheme="green"
            >
              Unduh
            </Button>
            <Flex width={2} />
            <Button
              onClick={() => {
                form.reset(data.getValue());
                onOpen();
              }}
            >
              Detail
            </Button>
          </>
        );
      },
    },
    {
      header: 'Tanggal',
      accessorKey: 'updated_at',
      cell: (data) => {
        return (
          new Date(data.getValue()).toLocaleDateString() + ' ' + new Date(data.getValue()).toLocaleTimeString() || '-'
        );
      },
    },
  ];

  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space between" mb="10px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Pesanan Produk
        </Text>
        {/* <Menu /> */}
      </Flex>
      <>
        <Table
          pagination={{
            pageIndex,
            pageSize,
            totalPages: data?.total_pages,
          }}
          setPagination={setPagination}
          data={data?.data}
          columns={columns}
        />
      </>
      <ModalVariantDetail isOpen={isOpenDataVariant} onClose={onCloseDataVariant} data={dataVariant} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Informasi Pembeli</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form methods={form}>
              <Flex>
                <FormInput
                  propsFormControl={{ mb: 4 }}
                  label="Nama"
                  inputProps={{
                    ...form.register('name'),
                    name: 'title',
                    placeholder: 'Nama',
                    disabled: true,
                  }}
                  errors={form.formState.errors}
                  type="TEXT"
                />
                <Flex width={10} />
                <FormInput
                  propsFormControl={{ mb: 4 }}
                  label="Nomor Telepon"
                  inputProps={{
                    ...form.register('phone_number'),
                    name: 'phone',
                    placeholder: 'Nomor Telepon',
                    disabled: true,
                  }}
                  errors={form.formState.errors}
                  type="TEXT"
                />
              </Flex>
              <FormInput
                propsFormControl={{ mb: 4 }}
                label="Kota"
                inputProps={{
                  ...form.register('address.city'),
                  name: 'address.city',
                  placeholder: 'Kota',
                  disabled: true,
                }}
                errors={form.formState.errors}
                type="TEXT"
              />
              <FormInput
                propsFormControl={{ mb: 4 }}
                label="Negara"
                inputProps={{
                  ...form.register('address.state'),
                  name: 'state',
                  placeholder: 'Negara',
                  disabled: true,
                }}
                errors={form.formState.errors}
                type="TEXT"
              />
              <Flex justify={'center'} align={'center'}>
                <FormInput
                  propsFormControl={{ mb: 4 }}
                  label="Alamat"
                  inputProps={{
                    ...form.register('address.street'),
                    name: 'street',
                    placeholder: 'Alamat',
                    disabled: true,
                    height: 100,
                  }}
                  errors={form.formState.errors}
                  type="TEXTAREA"
                />
                <Flex width={5} />
                <AiOutlineCopy
                  width={20}
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(form.getValues('address.street'));
                      alert('Text copied to clipboard');
                    } catch {
                      alert('Failed to copy text to clipboard');
                    }
                  }}
                />
              </Flex>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenStatusSeller} onClose={onCloseStatusSeller}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Shipping Detail</ModalHeader>
          <Form methods={formResi} onSubmit={onSubmitUpdateResi}>
            <ModalBody>
              <FormInput
                propsFormControl={{ mb: 4 }}
                label="Nomor Resi"
                inputProps={{
                  ...formResi.register('no_resi'),
                  name: 'no_resi',
                  placeholder: 'Nomor Resi',
                }}
                errors={form.formState.errors}
                type="TEXT"
              />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" colorScheme="blue" mr={3}>
                Save
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default ColumnsTable;

// PropTypes
ColumnsTable.propTypes = {
  columnsData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
};
