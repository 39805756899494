import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteDialog = (props) => {
  const { title, desc, handler, colorScheme = 'red', my, mx } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelReference = React.useRef();

  return (
    <>
      <Button my={my} mx={mx} colorScheme={colorScheme} variant="outline" onClick={onOpen}>
        {title}
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelReference} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{desc}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelReference} onClick={onClose}>
                Batalkan
              </Button>
              <Button
                colorScheme={colorScheme}
                onClick={() => {
                  handler(onClose);
                  onClose();
                }}
                ml={3}
              >
                {title}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
DeleteDialog.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  handler: PropTypes.func,
  colorScheme: PropTypes.string,
  my: PropTypes.string,
  mx: PropTypes.string,
};

export default DeleteDialog;
