import { Alert, AlertIcon, Box, FormControl, FormLabel, Input, Text, Textarea } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'components/searchSelect';
import ImageInput from './ImageInput';

const FormInput = ({ onClick, label, inputProps, type, errors, propsFormControl }) => {
  const InputComponent = () => {
    switch (type) {
      case 'SELECT': {
        return (
          <Select
            {...inputProps}
            name={inputProps.name}
            options={inputProps.optionsData}
            placeholder={inputProps.placeholder}
            closeMenuOnSelect={inputProps.closeMenuOnSelect}
            size={inputProps.size}
            onChange={inputProps.onChange}
          />
        );
      }
      case 'TEXTAREA': {
        return <Textarea {...inputProps} resize={'none'} />;
      }
      case 'IMAGE': {
        return <ImageInput {...inputProps} />;
      }

      default: {
        {
          return <Input {...inputProps} />;
        }
      }
    }
  };

  return (
    <FormControl {...propsFormControl}>
      {label && <FormLabel>{label}</FormLabel>}
      {InputComponent()}
      <ErrorMessage
        errors={errors}
        name={inputProps.name}
        render={({ message }) =>
          message && (
            <Alert status="error">
              <AlertIcon />
              <Box>
                <Text fontSize="sm" color="red.500">
                  {message}
                </Text>
              </Box>
            </Alert>
          )
        }
      />
    </FormControl>
  );
};
FormInput.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  inputProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    closeMenuOnSelect: PropTypes.bool,
    optionsData: PropTypes.array,
    size: PropTypes.string,
    onChange: PropTypes.func,
  }),
  errors: PropTypes.func,
  optionsData: PropTypes.array,
  propsFormControl: PropTypes.object,
  type: PropTypes.oneOf(['TEXTAREA', 'SELECT', 'TEXT']),
};

export default FormInput;
