import {
  Box,
  Flex,
  Button,
  Image,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  Text,
  Checkbox,
  Select,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import useStore from 'store/useStore';

const FormBanner = () => {
  const { getConfig, setConfig } = useStore();
  const form = useForm({
    defaultValues: {
      _id: '',
      config_name: 'banner',
      banner: [
        {
          file: null,
          preview: null,
        },
      ],
      description: [],
    },
  });
  const {
    watch,
    formState: { errors },
  } = form;

  const description = watch('description');
  const type = watch('type');

  const launch = async () => {
    const res = await getConfig({ config_name: 'banner' });
    if (res?.data) {
      const mapping = {
        ...res.data,
        banner: res?.data?.value?.map(
          (r) =>
            ({
              file: null,
              preview: r.image,
            }) || [
              {
                file: null,
                preview: null,
              },
            ],
        ) || [
          {
            file: null,
            preview: null,
          },
        ],
        description: res.data.value?.map((r) => r?.description) || [],
        type: res.data.value?.map((r) => r?.type) || [],
      };
      delete mapping.value;

      form.reset(mapping);
    }
  };

  useEffect(() => {
    launch();
  }, []);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    delete values._id;
    delete values.description;
    const castValues = {
      ...values,
      value: values.banner
        .filter((res) => res.file !== null && res.file !== undefined)
        .map((res, index) => {
          return {
            file: res.file,
            description: description[index],
            type: type[index],
          };
        }),
    };
    delete castValues.banner;
    delete castValues.type;
    await setConfig(castValues);
    setLoading(false);
  };

  return (
    <Form methods={form} onSubmit={onSubmit}>
      <FormInput
        propsFormControl={{ mb: 4 }}
        label="Tambah Dan Ubah Banner"
        inputProps={{
          ...form.register('banner'),
          name: 'banner',
          placeholder: 'Banner',
          form: form,
          multiple: true,
          childrenComponent: (index) => (
            <>
              <FormControl mb={4}>
                <FormLabel>Deskripsi</FormLabel>
                <Input type="text" name="name" placeholder="Deskripsi" {...form.register(`description.${index}`)} />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) =>
                    message && (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <Text fontSize="sm" color="red.500">
                            {message}
                          </Text>
                        </Box>
                      </Alert>
                    )
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormControl>
                  <FormLabel>Tipe</FormLabel>
                  <Select {...form.register(`type.${index}`)} placeholder="Pilih Tipe">
                    <option value={'mobile'}>Mobile</option>
                    <option value={'website'}>Website</option>
                  </Select>
                </FormControl>
              </FormControl>
            </>
          ),
        }}
        errors={errors}
        type="IMAGE"
      />
      <Button isLoading={loading} mt={10} colorScheme="blue" type="submit">
        Simpan
      </Button>
    </Form>
  );
};

export default FormBanner;
