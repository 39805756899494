import React from 'react';
import useStore from 'store/useStore';
import StoreDetail from './components/StoreDetail';
import StorePagination from './components/StorePagination';

const Store = () => {
  const { user } = useStore();
  return user.role === 1 ? <StorePagination /> : <StoreDetail />;
};

export default Store;
