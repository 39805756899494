/* eslint-disable prefer-arrow/prefer-arrow-functions */
import axios from 'axios';

export const apiProduction = 'https://api.dapatkomisi.com';

const axiosInstanceRemote = axios.create({
  baseURL: apiProduction + '/api/v1',
  withCredentials: true,
});
axiosInstanceRemote.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const res = await Api.refreshToken();
      axios.defaults.headers.common['Authorization'] = `Bearer ${res.access.token}`;
      originalRequest.headers['Authorization'] = `Bearer ${res.access.token}`;

      return axiosInstanceRemote(originalRequest);
    }
    throw error;
  },
);

export const Api = {
  options: (token) => {
    return {
      withCredentials: true,
      headers: { Authorization: `bearer ${token}` },
    };
  },
  refreshToken: async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken') || '';
      const res = await axiosInstanceRemote.post('/auth/refresh-tokens', {
        refresh_token: refreshToken,
      });
      localStorage.setItem('token', res.data.access.token);
      localStorage.setItem('refreshToken', res.data.refresh.token);

      return res.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  autoLogout: async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken') || '';
      await axiosInstanceRemote.post('/auth/logout', {
        refresh_token: refreshToken,
      });
    } catch (error) {
      throw error.response.data;
    }
  },
  get: async (url) => {
    try {
      const token = localStorage.getItem('token') || '';
      const res = await axiosInstanceRemote.get(url, Api.options(token));
      return res.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  post: async ({ url, data, isFormData }) => {
    try {
      const token = localStorage.getItem('token') || '';
      const formData = new FormData();

      const res = await axiosInstanceRemote.post(url, isFormData ? formData : data, Api.options(token));
      return res.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  put: async ({ url, data, isFormData }) => {
    try {
      const token = localStorage.getItem('token') || '';
      const formData = new FormData();
      for (const value in data) {
        if (value.includes('_date')) {
          formData.append(value, data[value].toISOString());
          continue;
        }
        formData.append(value, data[value]);
      }
      if (data.files) formData.set('files', data.files[0]);

      const res = await axiosInstanceRemote.put(
        url,
        isFormData ? { data: [Object.fromEntries(formData)] } : data,
        Api.options(token),
      );
      return res.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  delete: async (url) => {
    try {
      const token = localStorage.getItem('token') || '';
      const res = await axiosInstanceRemote.delete(url, Api.options(token));
      return res.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
