import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  Input,
  FormControl,
  FormLabel,
  AlertIcon,
  Alert,
  Text,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'components/form';
import Select from 'components/searchSelect';
import { categorySchema } from 'lib/schema';
import useStore from 'store/useStore';

const ModalForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const form = useForm({
    resolver: zodResolver(categorySchema),
    defaultValues: {
      name: '',
      sub_category: '',
      is_parent: false,
      type: 'digital',
    },
  });
  const {
    formState: { errors },
  } = form;
  const [categoryList, createCategoryHandler, createLoading, getCategoryHandler] = useStore((s) => [
    s.categoryList,
    s.createCategory,
    s.createLoading,
    s.getCategory,
  ]);

  const mappingCategoryList = categoryList?.data?.map((result) => ({
    label: result.name,
    ...result,
  }));

  useEffect(
    () => () => {
      if (!isOpen) {
        form.reset({
          name: '',
          sub_category: '',
        });
      }
    },
    [isOpen],
  );

  const onSubmitFinished = () => {
    onClose();
    getCategoryHandler();
  };

  const onSubmit = async (values) => {
    const castValues = {
      ...values,
    };
    if (castValues.sub_category.length < 1) {
      delete castValues.sub_category;
    }
    await createCategoryHandler(castValues, onSubmitFinished);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} px={10} py={4}>
      <Button alignSelf={'flex-end'} colorScheme="blue" onClick={onOpen}>
        Add Category
      </Button>
      <Modal blockScrollOnMount={false} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box px={'10px'} py={'10px'} mb="10px">
              <Form methods={form} onSubmit={onSubmit}>
                <FormControl mb={4}>
                  <FormLabel>NAMA KATEGORI</FormLabel>
                  <Input type="text" name="name" placeholder="NAMA KATEGORI" {...form.register('name')} />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Sub Category</FormLabel>
                  <Select
                    name="sub_category"
                    options={mappingCategoryList}
                    placeholder="Select sub category"
                    closeMenuOnSelect
                    size="md"
                    onChange={(value) => form.setValue('sub_category', value?._id)}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="sub_category"
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Category Utama</FormLabel>
                  <Select
                    options={[
                      {
                        label: 'iya',
                        value: 'true',
                      },
                      {
                        label: 'tidak',
                        value: 'false',
                      },
                    ]}
                    onChange={({ value }) => form.setValue(`is_parent`, Boolean(value))}
                    placeholder="Select option"
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`is_parent`}
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Tipe Category</FormLabel>
                  <Select
                    options={[
                      {
                        label: 'digital',
                        value: 'digital',
                      },
                      {
                        label: 'non-digital',
                        value: 'non-digital',
                      },
                    ]}
                    onChange={({ value }) => form.setValue(`type`, value)}
                    placeholder="Select option"
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`is_parent`}
                    render={({ message }) =>
                      message && (
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <Text fontSize="sm" color="red.500">
                              {message}
                            </Text>
                          </Box>
                        </Alert>
                      )
                    }
                  />
                </FormControl>
                <Button isLoading={createLoading} mt={10} colorScheme="teal" type="submit">
                  Save
                </Button>
              </Form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalForm;
