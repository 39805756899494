import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { Toaster } from 'react-hot-toast';
import { HashRouter, Switch } from 'react-router-dom';
import Router from 'routes';
import theme from 'theme/theme.js';

ReactDOM.render(
  <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom' } }}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <HashRouter>
          <Switch>
            <Router />
          </Switch>
        </HashRouter>
        <Toaster />
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.querySelector('#root'),
);
