import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Form from 'components/form';
import FormInput from 'components/form/Input';
import RatingStars from 'components/rating';
import Table from 'components/table';
import useStore from 'store/useStore';
import MyProduct from 'views/admin/product/components/MyProduct';
import AddAddressModal from './AddAddressModal';
import AddressCard from './AddressCard';
import ModalFormJoinCommunity from './ModalFormJoinCommunity';

const CommunityList = (props) => {
  const { community } = props;
  const columns = [
    { header: 'Community Name', accessorKey: 'name' },
    //react table if average_weight is null, it will return 0
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => data.getValue() ?? '-',
    },
    {
      header: 'Image',
      accessorKey: 'image',
      cell: (data) => <Image maxHeight={100} objectFit={'cover'} src={data?.getValue()} borderRadius="16px" />,
    },
    {
      header: 'Address',
      accessorKey: 'address',
      cell: (data) => data.getValue() ?? '-',
    },
  ];

  return <Table showAll data={community} columns={columns} />;
};
CommunityList.propTypes = {
  community: PropTypes.array,
};

const StoreDetail = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const history = useHistory();
  const { getAddress, address, getStoreAddress, myStore, my_store, updateStore, getDetailStore, user } = useStore();
  const form = useForm({
    defaultValues: {
      file: '',
      name: '',
    },
  });

  useEffect(() => {
    if (id) {
      getDetailStore({ store_id: id });
      getStoreAddress({ store_id: id });
    } else {
      getAddress();
      myStore();
    }
  }, []);
  const {
    formState: { errors, isSubmitting },
  } = form;
  const [activeAddress, setActiveAddress] = useState(null);

  useEffect(() => {
    if (address?.length > 0) {
      setActiveAddress(address?.filter((res) => res.is_main_address === true)?.[0]?._id);
    }
    if (my_store.length > 0) {
      form.setValue('file', my_store[0].image);
      form.setValue('name', my_store[0].name);
    }
  }, [address, my_store]);

  const handleToggleActive = (id) => {
    setActiveAddress(id);
  };

  const onSubmit = async (data) => {
    if (typeof data.file === 'string') {
      delete data.file;
    }
    toast
      .promise(
        updateStore({ ...data, store_id: my_store[0]._id }),
        {
          loading: 'Memuat...',
          success: 'Memperbarui Data Toko',
          error: 'Terjadi Kesalahan',
        },
        {
          position: 'bottom-center',
        },
      )
      .finally(() => {
        onClose();
        history.go();
      });
  };

  return (
    <>
      <Modal scrollBehavior="outside" onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ubah Toko</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form methods={form} onSubmit={onSubmit}>
              <Flex
                justify={'center'}
                align={'center'}
                borderRadius={'2xl'}
                px={10}
                py={10}
                flexDirection={'column'}
                backgroundColor={'white'}
              >
                <FormInput
                  label="Gambar Toko"
                  propsFormControl={{ mb: 4 }}
                  inputProps={{
                    ...form.register('file'),
                    name: 'file',
                    form: form,
                  }}
                  errors={errors}
                  type="IMAGE"
                />
                <Flex width={'40px'} />
                <Flex width={'100%'} flexDirection={'column'}>
                  <FormInput
                    propsFormControl={{ mb: 4 }}
                    label="Nama Toko"
                    inputProps={{
                      ...form.register('name'),
                      name: 'name',
                      placeholder: 'Name',
                    }}
                    errors={errors}
                    type="TEXT"
                  />
                  {user.role !== 1 && (
                    <Button mt={4} isLoading={isSubmitting} colorScheme="blue" type="submit">
                      Perbarui Toko
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        pt={{ base: '130px', md: '80px', xl: '80px' }}
        display={'flex'}
        flexDirection={'column'}
        backgroundColor={'white'}
      >
        <Flex flexDirection={'column'} backgroundColor={'white'} px={4}>
          <Flex py={10}>
            <Flex boxShadow="md" flexDirection={'column'} width={'max-content'} p={4}>
              <Flex bg={'red.400'} width={120} height={120} borderRadius={'full'} p={1}>
                <Image
                  src={my_store?.[0]?.image}
                  width={'full'}
                  height={'full'}
                  objectFit={'cover'}
                  borderRadius={'full'}
                  borderWidth={10}
                  borderColor={'red'}
                />
              </Flex>
              <Text textAlign={'center'} mt={2} fontWeight={'bold'} color={'red.600'}>
                {my_store?.[0]?.name}
              </Text>
              <Flex align={'center'} justify={'center'} mt={2}>
                <RatingStars rating={1} totalStars={1} />
                <Text fontWeight={'bold'} fontSize={'md'} ml={1}>
                  {my_store?.[0]?.rating}
                </Text>
              </Flex>
              {user.role !== 1 && (
                <Button colorScheme="blue" onClick={onOpen} mt={4}>
                  Ubah Toko
                </Button>
              )}
              <Flex mt={4} width={'full'} height={0.5} bg={'gray.200'} />
            </Flex>
            <Flex
              overflowX={'scroll'}
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                // '&::-webkit-scrollbar-thumb': {
                //   background: scrollbarColor,
                //   borderRadius: '24px',
                // },
              }}
              width={'100%'}
              height={'100%'}
              alignItems="start"
              gap={4}
            >
              {address.length > 0 &&
                address.map((res, index) => (
                  <>
                    {res?.address && (
                      <>
                        <AddressCard
                          isActive={activeAddress === res._id}
                          onToggleActive={handleToggleActive}
                          key={index}
                          id={res._id}
                          address={res?.address?.street + '\n' + res?.address?.city}
                        />
                      </>
                    )}
                  </>
                ))}
              {user.role !== 1 && <AddAddressModal />}
            </Flex>
          </Flex>
          <Flex
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            height={'100%'}
          >
            <Flex shadow="md" borderRadius={'md'} width={'100%'} height={'100%'}>
              <Flex width={1} backgroundColor={'red.300'} height={'auto'} />
              <Flex padding={'2'} alignItems={'center'}>
                <Flex bg={'red.400'} width={30} height={30} borderRadius={'full'} p={1}>
                  <Image
                    src={my_store?.[0]?.community?.[0]?.image}
                    width={'full'}
                    height={'full'}
                    objectFit={'cover'}
                    borderRadius={'full'}
                    borderWidth={10}
                    borderColor={'red'}
                  />
                </Flex>
                <Text ml={2} fontWeight={'bold'}>
                  Komunitas {my_store?.[0]?.community?.[0]?.name}
                </Text>
              </Flex>
            </Flex>
            {user.role !== 1 && <ModalFormJoinCommunity />}
          </Flex>
        </Flex>

        <Tabs isLazy>
          <TabList>
            <Tab>Produk</Tab>
          </TabList>
          <TabPanels>
            <TabPanel background={'white'}>
              <MyProduct store_id={id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default StoreDetail;
