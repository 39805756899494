import { StarIcon } from '@chakra-ui/icons';
import { Box, Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const RatingStars = ({ rating, totalStars = 5 }) => {
  const renderStar = (index) => {
    if (rating > index && rating < index + 1) {
      // Half-filled star
      return (
        <Box position="relative" display="flex">
          <StarIcon color="gray.300" />
          <StarIcon
            color="orange.500"
            position="absolute"
            top="0"
            left="0"
            clipPath="polygon(0 0, 50% 0, 50% 100%, 0% 100%)" // Clip to half
          />
        </Box>
      );
    } else if (rating > index) {
      // Full star
      return <StarIcon color="orange.500" />;
    } else {
      // Empty star
      return <StarIcon color="gray.300" />;
    }
  };

  // Create an array of stars to be displayed
  const stars = Array.from({ length: totalStars }, (_, index) => renderStar(index));

  return <Box display="flex">{stars}</Box>;
};

RatingStars.propTypes = {
  totalStars: PropTypes.number,
  rating: PropTypes.number,
};

export default RatingStars;
