import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import useStore from 'store/useStore';
import ModalWithDraw from './components/Modal';
import ColumnsTable from './components/table';

const WalletPage = () => {
  const { getWalletHistory } = useStore();
  useEffect(() => {
    toast.promise(
      getWalletHistory(),
      {
        loading: 'Memuat..',
        success: 'Mendapatkan riwayat dompet',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, []);
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <ModalWithDraw />
      <ColumnsTable />
    </Box>
  );
};

export default WalletPage;
