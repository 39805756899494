import { Button, Icon, IconButton, Image, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import PropTypes from 'prop-types'; // Import PropTypes
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import RatingStars from 'components/rating';
import Table from 'components/table';
import useStore from 'store/useStore';
import ModalFormJoinCommunity from './ModalFormJoinCommunity';

const StorePagination = () => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const pageParameter = query?.get('page');
  const limitParameter = query?.get('limit');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const history = useHistory();
  const { store_list, getStore } = useStore();
  const columns = [
    {
      header: 'Store Name',
      accessorKey: 'name',
      cell: (data) => <>{data.getValue()}</>,
    },
    {
      header: 'Rating',
      accessorKey: 'rating',
      cell: (data) => <RatingStars rating={data.getValue() ?? 0} />,
    },
    {
      header: 'Image',
      accessorKey: 'image',
      cell: (data) => <Image maxHeight={150} objectFit={'cover'} src={data?.getValue()} borderRadius="16px" />,
    },
    {
      header: 'aksi',
      accessorKey: '_id',
      cell: (data) => (
        <div className="flex gap-2">
          <Button
            onClick={() => {
              history.push(`/admin/store/${data.row.original._id}`);
            }}
            colorScheme="blue"
            size="md"
            mr={2}
          >
            Detail
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (pageParameter) {
      setPagination((res) => ({
        ...res,
        pageIndex: Number(pageParameter),
        pageSize: Number(limitParameter),
      }));
    }
  }, [JSON.stringify(pageParameter)]);

  useEffect(() => {
    toast.promise(
      getStore({
        page: pageIndex + 1,
        limit: pageSize,
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Data Store',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);
  return (
    <>
      <Table
        pagination={{
          pageIndex,
          pageSize,
          totalPages: store_list?.total_pages,
        }}
        setPagination={setPagination}
        data={store_list?.data}
        columns={columns}
      />
    </>
  );
};

export default StorePagination;
// PropTypes
StorePagination.propTypes = {};
