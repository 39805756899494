import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import FormBanner from './components/FormBanner';
import FormCommission from './components/FormCommission';
import FormPrivacyAndPolicy from './components/FormPrivacyAndPolicy';

const Config = () => {
  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      display={'flex'}
      flexDirection={'column'}
      backgroundColor={'white'}
    >
      <Tabs isLazy>
        <TabList>
          <Tab>Banner</Tab>
          <Tab>Privasi And Policy</Tab>
          <Tab>Komisi Platform</Tab>
        </TabList>
        <TabPanels>
          <TabPanel background={'white'}>
            <FormBanner />
          </TabPanel>
          <TabPanel background={'white'}>
            <FormPrivacyAndPolicy />
          </TabPanel>
          <TabPanel background={'white'}>
            <FormCommission />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Config;
