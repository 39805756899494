import { Button, IconButton, Image, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineCopy } from 'react-icons/ai';
import { IoMdSettings } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteDialog from 'components/deleteDialog';
import Table from 'components/table';
import useStore from 'store/useStore';
import ModalUpdate from './ModalUpdate';

const CommunityPagination = () => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const pageParameter = query?.get('page');
  const limitParameter = query?.get('limit');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const history = useHistory();
  const [communityData, setCommunityData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { communityList, getCommunity, deleteCommunity, updateCommunity } = useStore();

  const columns = [
    {
      header: 'Nama Komunitas',
      accessorKey: 'name',
      cell: (data) => (
        <>
          {data.getValue()}
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover="none"
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            icon={
              <IoMdSettings
                onClick={() => {
                  history.push(`/admin/community/${data.row.original._id}`);
                }}
                w="15px"
                h="15px"
              />
            }
          ></IconButton>
        </>
      ),
    },
    //react table if average_weight is null, it will return 0
    {
      header: 'Deskripsi',
      accessorKey: 'desc',
      cell: (data) => <Text whiteSpace={'break-spaces'}>{data.getValue() ?? '-'}</Text>,
    },
    {
      header: 'Gambar',
      accessorKey: 'image',
      cell: (data) => <Image maxHeight={150} objectFit={'cover'} src={data?.getValue()} borderRadius="16px" />,
    },
    {
      header: 'Referral Code',
      accessorKey: 'referral_code',
      cell: ({ getValue }) => (
        <div className="flex gap-2">
          {getValue() ?? '-'}
          <AiOutlineCopy
            width={20}
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(getValue() ?? '-');
                alert('Text copied to clipboard');
              } catch {
                alert('Failed to copy text to clipboard');
              }
            }}
          />
        </div>
      ),
    },
    {
      header: 'aksi',
      accessorKey: '_id',
      cell: (data) => (
        <div className="flex gap-2">
          <Button
            onClick={() => {
              // setVariantsData(productList.data[data.row.index]);
              setCommunityData(communityList.data[data.row.index]);
              onOpen();
            }}
            colorScheme="blue"
            size="md"
          >
            Ubah
          </Button>
          <DeleteDialog
            mx={'5px'}
            title={data.row.original.status === 'suspend' ? 'Active' : 'Suspend'}
            desc={`Anda Yakin Ingin Melakukan Ini?`}
            handler={async (onClose) => {
              await updateCommunity(data.row.original._id, {
                status: data.row.original.status === 'suspend' ? 'active' : 'suspend',
              });
              toast.promise(
                getCommunity({
                  page: pageIndex + 1,
                  limit: pageSize,
                }),
                {
                  loading: 'Memuat...',
                  success: 'Mendapatkan Data Komunitas',
                  error: 'Terjadi Kesalahan',
                },
                {
                  position: 'bottom-center',
                },
              );
              onClose();
            }}
            colorScheme={data.row.original.status === 'suspend' ? 'green' : 'red'}
          />
          {/* <Button
           
            size="md"
            mr={2}
          >
            {data.row.original.status === 'suspend' ? 'active' : 'suspend'}
          </Button> */}
          <DeleteDialog
            title={`Hapus`}
            desc={`Anda Yakin Ingin Melakukan Ini?`}
            handler={(callback) => {
              toast.promise(
                deleteCommunity(data.getValue()),
                {
                  loading: 'Memuat...',
                  success: 'Menghapus Data Komunitas',
                  error: 'Terjadi Kesalahan',
                },
                {
                  position: 'bottom-center',
                },
              );
              toast.promise(
                getCommunity({
                  page: pageIndex + 1,
                  limit: pageSize,
                }),
                {
                  loading: 'Memuat...',
                  success: 'Mendapatkan Data Komunitas',
                  error: 'Terjadi Kesalahan',
                },
                {
                  position: 'bottom-center',
                },
              );
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (pageParameter) {
      setPagination((res) => ({
        ...res,
        pageIndex: Number(pageParameter),
        pageSize: Number(limitParameter),
      }));
    }
  }, [JSON.stringify(pageParameter)]);

  useEffect(() => {
    toast.promise(
      getCommunity({
        page: pageIndex + 1,
        limit: pageSize,
      }),
      {
        loading: 'Memuat...',
        success: 'Mendapatkan Data Komunitas',
        error: 'Terjadi Kesalahan',
      },
      {
        position: 'bottom-center',
      },
    );
  }, [pageIndex, pageSize]);
  return (
    <>
      <ModalUpdate isOpen={isOpen} onClose={onClose} onOpen={onOpen} communityData={communityData} />
      <Table
        pagination={{
          pageIndex,
          pageSize,
          totalPages: communityList?.total_pages,
        }}
        setPagination={setPagination}
        data={communityList?.data}
        columns={columns}
      />
    </>
  );
};

export default CommunityPagination;
// PropTypes
CommunityPagination.propTypes = {};
