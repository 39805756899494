import PropTypes from 'prop-types'; // Import PropTypes
import React from 'react';
import { FormProvider } from 'react-hook-form';

const Form = ({ methods, onSubmit = console.log, children, className }) => (
  <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
      {children}
    </form>
  </FormProvider>
);

// Define PropTypes
Form.propTypes = {
  methods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Form;
