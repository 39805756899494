import { isAxiosError } from 'axios';
import toast from 'react-hot-toast';
import { Api } from 'lib/api';
import objectToFormData from 'lib/objectToFormData';

const initialState = {
  user: null,
  token: '',
  wallet: {},
  wallet_history: [],
  my_store: {},
};

const createAuthSlice = (set) => ({
  ...initialState,
  loadUser: () => {
    if (localStorage.getItem('user')) {
      const User = JSON.parse(localStorage.getItem('user') || '{}');
      set((state) => ({ ...state, user: User }));
    }
  },
  getWallet: async () => {
    try {
      const res = await Api.get('admin/wallet');
      set((state) => ({ ...state, wallet: res.data }));
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      throw new Error(error);
    }
  },
  withdraw: async (data) => {
    try {
      await Api.post({ url: 'admin/wallet/withdraw', data });
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      throw new Error(error);
    }
  },
  myStore: async () => {
    try {
      const res = await Api.get('store/my-store');
      set((state) => ({ ...state, my_store: res.data }));
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      throw new Error(error);
    }
  },
  updateStore: async (data) => {
    try {
      const formData = new FormData();
      objectToFormData(data, formData);
      await Api.post({ url: 'store', data: formData });
    } catch (error) {
      if (isAxiosError(error)) toast.error(error?.response?.data?.message);
      throw new Error(error);
    }
  },
  getWalletHistory: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('admin/wallet/history?' + query);
      set((state) => ({ ...state, wallet_history: res }));
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
  signin: async (data) => {
    try {
      const res = await Api.post({
        url: '/auth/login',
        data,
      });
      localStorage.setItem('user', JSON.stringify(res.user));
      localStorage.setItem('token', res.tokens.access.token);
      localStorage.setItem('refreshToken', res.tokens.refresh.token);
      set((state) => ({ ...state, user: res.user }));
      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
      throw new Error(error);
    }
  },
  logout: async () => {
    try {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      set((state) => ({ ...state, user: null }));
    } catch {
      toast({ type: 'error', message: 'Something went wrong!' });
    }
  },
  register: async (data) => {
    try {
      const res = await Api.post({ url: '/api/auth/register', data });
      toast({ type: 'success', message: res.message });
      window.location.reload();
    } catch (error) {
      toast({ type: 'error', message: error.data.error });
    }
  },
  updateUser: async (data) => {
    try {
      const res = await Api.post({
        url: '/api/user/update',
        data: { data: [data] },
      });
      const newData = await Api.get('/api/user/get/detail/' + data._id);
      localStorage.setItem('user', JSON.stringify(newData.data));
      toast({ type: 'success', message: res.message });
      window.location.reload();
    } catch (error) {
      toast({ type: 'error', message: error.data.error });
    }
  },
  changeRole: async (data) => {
    try {
      const res = await Api.post({
        url: '/api/user/role/update',
        data: { data: [data] },
      });
      const newData = await Api.get('/api/user/get/detail/' + data._id);
      localStorage.setItem('user', JSON.stringify(newData.data));
      toast({ type: 'success', message: res.message });
      window.location.reload();
    } catch (error) {
      toast({ type: 'error', message: error.data.errors[0].msg });
    }
  },
  deleteUser: async (id) => {
    try {
      const res = await Api.delete('/api/user/delete/' + id);
      toast({ type: 'success', message: res.message });
      window.location.reload();
    } catch (error) {
      toast({ type: 'error', message: error.data.errors[0].msg });
    }
  },
  updateProfile: async (data) => {
    try {
      const res = await Api.post({
        url: '/api/user/update',
        data: { data: [data] },
      });
      const newData = await Api.get('/api/user/get/detail/' + data._id);
      localStorage.setItem('user', JSON.stringify(newData.data));
      toast({ type: 'success', message: res.message });
      window.location.reload();
    } catch (error) {
      toast({ type: 'error', message: error.data.error });
    }
  },
  changePass: async (data) => {
    try {
      const res = await Api.post({ url: '/api/user/password/update', data });
      toast({ type: 'success', message: res.message });
      window.location.reload();
    } catch (error) {
      toast({ type: 'error', message: error.data.errors[0].msg });
    }
  },
});

export default createAuthSlice;
