import { isAxiosError } from 'axios';
import { toast } from 'components/toast';
import { Api } from 'lib/api';

const initialState = {
  ordered_product: [],
};

const createOrderSlice = (set) => ({
  ...initialState,
  getOrderedProduct: async (data) => {
    try {
      const query = new URLSearchParams(data).toString();
      const res = await Api.get('/admin/order?' + query);
      set((s) => ({ ...s, ordered_product: res }));

      return res;
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
  updateResi: async (data) => {
    try {
      await Api.post({
        url: '/admin/order/update-resi',
        data,
      });
    } catch (error) {
      if (isAxiosError(error)) toast({ type: 'error', message: error.response?.data.message });
    }
  },
});

export default createOrderSlice;
